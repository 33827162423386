import React from "react";
import { Route } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import classnames from "classnames";
// @material-ui/core components
import { makeStyles } from '@mui/styles';
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// @material-ui/icons
import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
// core components
import styles from "./tasksStyle.js";
import { TableHead } from "@mui/material";

import { useState } from "react";
import { Button, Modal, Row, Col} from "react-bootstrap";
import PermisosService from "../../services/permisos.service";
import { toast } from 'react-toastify';

const useStyles = makeStyles(styles);


export default function Tasks(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState([...props.checkedIndexes]);
  const [dataTableHeader, setDataTableHeader] = React.useState(["Permisos Activados", "Nombre", "Acciones"]);
  const [showToast, setShowToast] = useState(false);
  const [auxElegido, setAuxElegido] = useState("");

    // Modal Card//
    const [tituloModal, setTituloModal] = useState("");
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    // Fin Modal Card//

    
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const { tasksIndexes, tasks, rtlActive } = props;
  const tableCellClasses = classnames(classes.tableCell, {
    [classes.tableCellRTL]: rtlActive
  });

  
  const cerrarModal = () => {
    setShow(false);
  }
  const cerrarModalCardResetPermisos = () => {
    PermisosService.updateResetPermisos(auxElegido).then(
      (response) => {
        console.log(response.data);
        window.location.reload();
        toast.success(<div>Permisos Reseteados</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
          });
      },
      (error) => {
        console.log(error);
      }
    ); 
  }

  return (
    
    <Table className={classes.table}>
      <Modal show={show} fullscreen={fullscreen} onHide={() => cerrarModal()} >
        <Modal.Header>
          <Modal.Title>Alerta</Modal.Title>
          <Button variant="outline-danger" onClick={() => cerrarModal()}>X</Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <Col>
              Desea Resetear los permisos?
            </Col>
          </Row>
        </Modal.Body>
       <Modal.Footer>
          <Button variant="outline-danger" onClick={() => cerrarModalCardResetPermisos()}>Resetear</Button>
          <Button variant="success" onClick={() => cerrarModal()}>Salir</Button>
       </Modal.Footer>
      </Modal>
      <TableHead>
        <TableRow >
          {dataTableHeader.map(value => (
            <TableCell key={value}> <b>{value}</b> </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map(value => (
          <TableRow key={value.user.id} className={classes.tableRow}>
            <TableCell className={tableCellClasses}>
              <Checkbox
                checked={value.permisos.length > 0 ? true : false}
                tabIndex={-1}
                onClick={() => handleToggle(value)}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.root
                }}
              />
            </TableCell>
            <TableCell className={tableCellClasses}>{value.user.empleado.Nombre + " " + value.user.empleado.Apellido}</TableCell>
            <TableCell className={classes.tableActions}>
              {
                value.permisos.length > 0 ? 
                  <Tooltip>
                      <Tooltip
                        id="tooltip-top"
                        title="Editar permisos"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                        
                      >
                        <Button
                          onClick={() => navigate('/admin/permisos/editar/'+ value.user.id)}
                          style={{backgroundColor: "transparent", border: "none"}}
                          >
                          <IconButton
                            aria-label="Edit"
                            className={classes.tableActionButton}
                          >
                          <Edit
                            className={
                              classes.tableActionButtonIcon + " " + classes.edit
                            }
                          />
                          </IconButton>
                        </Button>
                      </Tooltip>
                      <Tooltip
                        id="tooltip-top-start"
                        title="Eliminar permisos"
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          onClick={() => {
                            setAuxElegido(value.user.id);
                            setShow(true);
                          }}
                          style={{backgroundColor: "transparent", border: "none"}}
                          >
                        <IconButton
                          aria-label="Close"
                          className={classes.tableActionButton}
                        >
                          <Close
                            className={
                              classes.tableActionButtonIcon + " " + classes.close
                            }
                          />
                        </IconButton>
                        </Button>
                      </Tooltip>
                  </Tooltip>
              :
              <Tooltip
              id="tooltip-top"
              title="Editar permisos"
              placement="top"
              classes={{ tooltip: classes.tooltip }}
              >
              <Button
                onClick={() => navigate('/admin/permisos/editar/'+ value.user.id)}
                style={{backgroundColor: "transparent", border: "none"}}
                >
                  <IconButton
                    aria-label="Edit"
                    className={classes.tableActionButton}
                  >
                  <Edit
                    className={
                    classes.tableActionButtonIcon + " " + classes.edit
                    }
                  />
                  </IconButton>
              </Button>
            </Tooltip>
            } 
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    
  );
}


Tasks.propTypes = {
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.arrayOf(PropTypes.node),
  rtlActive: PropTypes.bool,
  checkedIndexes: PropTypes.array
};
