import React, { useState, useEffect } from "react";
import {
  MDBCard,
  MDBInputGroup,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCarousel,
  MDBCarouselElement,
  MDBCarouselInner,
  MDBCarouselItem,
} from "mdb-react-ui-kit";
import ArticuloService from "../services/articulo.service";
import Switch from "react-switch";
import { Container, Row, Col, Toast } from "react-bootstrap/";
import { PlusSquare, DashSquare } from "react-bootstrap-icons";
//import { Button } from "bootstrap";
import { Button, Modal, Image } from "react-bootstrap/";
import { toast } from "react-toastify";

import { useCart } from "react-use-cart";

import { useTimer } from "react-timer-hook";

import {} from "react-bootstrap";

import { PDFViewer } from "@react-pdf/renderer";

import CodigosDeBarra from "./reports/CodigosDeBarra";
var JsBarcode = require("jsbarcode");
// Canvas v1
var Canvas = require("canvas");

require("dotenv").config();
const API_URL = process.env.REACT_APP_URL;

const Card = (rowData) => {
  //const BASEURL = "http://localhost:8080/Images/";
  const [content, setContent] = useState({ rowData: [] });
  const [resultado, setResultado] = useState(0);
  const [image, setImage] = useState();
  const [BASEURL, setBASEURL] = useState();
  const [ganancia, setGanancia] = useState();
  const [cantidad, setCantidad] = useState(1);
  const [totalFinal, setTotalFinal] = useState();
  const [switchChecked, setSwitchChecked] = useState(false);
  const [formato, setFormato] = useState(rowData.children[7]);
  const [formatoFiltrado, setFormatoFiltrado] = useState();
  const [unidadVisible, setUnidadVisible] = useState(false);
  const [cantidadIngresada, setCantidadIngresada] = useState("");
  const [elegido, setElegido] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [observacionElegido, setObservacionElegido] = useState("");
  const [cantidadEtiquetas, setCantidadEtiquetas] = useState(1);
  const [barcode, setBarcode] = useState([]);

  // Modal //
  const [tituloModal, setTituloModal] = useState("Imprimir Codigos de Barra");
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  // Fin Modal //

  const toggleShowA = () => setShowToast(!showToast);

  const { addItem, updateItemQuantity } = useCart();
  const { items } = useCart();
  const { getItem } = useCart();
  const time = new Date();
  time.setSeconds(0.5); // time.getSeconds() + 0.5
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    time,
    onExpire: () => {
      if (parseFloat(cantidadIngresada) >= 0) {
        let id = content.data[0];
        let elegido = items.findIndex((element) => element.id === id);
        if (elegido === -1) {
          // No lo encuentra, lo agrego
          let product = {
            id: rowData.children[0],
            name: rowData.children[2],
            price: rowData.children[5],
            observacion: document.getElementById("observacion").value,
          };
          addItem(product, parseFloat(cantidadIngresada));
          toast.success(
            <div>
              Artículo {rowData.children[2]}
              <br />
              Cantidad: {cantidadIngresada} <br /> Agregado correctamente
            </div>,
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              whiteSpace: "pre-line",
            }
          );
        } else {
          // Lo encuentra, lo updateo
          items[elegido].observacion = observacionElegido;
          items[elegido].quantity = parseFloat(cantidadIngresada);
          updateItemQuantity(id, parseFloat(cantidadIngresada));
          toast.success("Cantidad cambiada correctamente", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          });
        }
      }
    },
  });

  //const images= [{url:BASEURL+"20200827_11_38_12_621.png"},{url: BASEURL+"20200827_11_38_23_490.png"},{url: BASEURL+"20200827_11_38_32_879.png"}];
  useEffect(() => {
    /*
    const myItem = getItem(rowData.children[0]);
    if(myItem!=undefined){
      setCantidadEnElCarrito(myItem.quantity);
    }
    */

    let user = JSON.parse(localStorage.getItem("user"));
    setGanancia(user.ganancia);
    setContent({ data: rowData.children });

    console.log(
      "Resultado: " +
        parseFloat(rowData.children[5]) * parseFloat(user.ganancia)
    );

    let resultadoInicial = 0;
    if (parseFloat(user.ganancia) > 100) {
      let calculin =
        parseFloat(rowData.children[5]) / (100 / parseFloat(user.ganancia));
      let aux = 100 / parseFloat(user.ganancia);
      resultadoInicial = calculin + parseFloat(rowData.children[5]);
    } else {
      let porcentaje = parseFloat(user.ganancia) / 100 + 1;
      resultadoInicial =
        parseFloat(porcentaje) * parseFloat(rowData.children[5]);
    }
    setResultado(resultadoInicial);
    setTotalFinal(resultadoInicial);

    // Veo si está en el carrito o no

    let elegido = items.find((element) => element.id === rowData.children[0]);
    if (elegido != undefined) {
      setElegido(elegido);
      if (elegido.observacion != "") {
        setObservacionElegido(elegido.observacion);
      }
    }
    // Fin Veo si está en el carrito o no

    setBASEURL(API_URL + "/Images/" + rowData.children[0] + "/");
    ArticuloService.getFotosArticulo(rowData.children[0]).then(
      (response) => {
        console.log("ID:" + rowData.children[0]);
        console.log(response.data.files);
        if (response.data.files[0] == "Gris.png") {
          setImage("/public/Gris.png");
        } else {
          setImage(response.data.files);
        }
        //response.data.map((articulo) => console.log(articulo));
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setContent(_content);
      }
    );
  }, [rowData]);

  const handleSwitchChange = (checked) => {
    calculoPrecioUnitario();
    setSwitchChecked(checked);
  };
  const calculoPrecioUnitario = () => {
    if (!switchChecked) {
      console.log("Antes del regex: ", formato);
      var matches = formato.match(/[+-]?([0-9]*[.])?[0-9]+/);
      if (matches == null) {
        setFormatoFiltrado(1);
      } else {
        setFormatoFiltrado(matches[0]);
        setResultado(
          parseFloat(totalFinal) / parseFloat(matches[0]) / parseFloat(cantidad)
        );
        setTotalFinal(parseFloat(totalFinal) / parseFloat(matches[0]));
      }
      setUnidadVisible(true);
      console.log("Después del regex: " + formatoFiltrado);
    } else {
      setUnidadVisible(false);
      cambioCalculo();
    }
  };
  const cambioCalculo = () => {
    let auxGanancia = document.getElementById("ganancia").value;
    if (auxGanancia === "") {
      auxGanancia = ganancia;
    }
    let auxPrecio;
    if (parseFloat(auxGanancia) > 100) {
      let calculin =
        parseFloat(content.data[5]) / (100 / parseFloat(auxGanancia));
      auxPrecio = calculin + parseFloat(content.data[5]);
    } else {
      let porcentaje = parseFloat(auxGanancia) / 100 + 1;
      auxPrecio = parseFloat(porcentaje) * parseFloat(content.data[5]);
    }
    let auxPrecioFinal = auxPrecio;
    let auxCantidad = document.getElementById("cantidad").value;
    if (auxCantidad === "") {
      auxCantidad = 1;
    }
    setResultado(parseFloat(auxPrecioFinal));
    setTotalFinal(parseFloat(auxCantidad) * parseFloat(auxPrecioFinal));
  };

  const addCarrito = () => {
    console.log("Add Carrito");
    let product = {
      id: rowData.children[0],
      name: rowData.children[2],
      price: rowData.children[5],
      observacion: document.getElementById("observacion").value,
    };
    let cantidadItem = parseFloat(
      document.getElementById("cantidadItem").value
    );
    if (parseFloat(cantidadItem) > 0) {
      addItem(product, cantidadItem);
      console.log("En el carrito");
      console.log(items);
    }
    /*
      const myItem = getItem(rowData.children[0]);
      setCantidadEnElCarrito(myItem.quantity);
      toggleShowA();
      */
  };
  const sacarCarrito = () => {
    console.log("Sacar Carrito");
    let product = {
      id: rowData.children[0],
      name: rowData.children[2],
      price: rowData.children[5],
      observacion: document.getElementById("observacion").value,
    };
    let cantidadItem = parseFloat(
      document.getElementById("cantidadItem").value
    );
    addItem(product, cantidadItem);
    console.log("En el carrito");
    console.log(items);
  };

  const imprimirCodigoBarras = () => {
    /*
      console.log(cantidadEtiquetas);
      var canvas = document.createElement('canvas');
      var barcode;
      JsBarcode(canvas, content.data[1])
      barcode = canvas.toDataURL();
      let aux = []
      let i = 0;
      let j = 0 ;
      const tope = 6;
      let auxCantidadEtiquetas = cantidadEtiquetas/tope;
      let auxi = auxCantidadEtiquetas;
      while(i< auxCantidadEtiquetas){
        let fila = [];

        if(auxi > 1){
          while(j<tope){
            fila.push(barcode);
            j++;
          }
        }
        else{
          let numerin = auxi*tope;
          let redondo = Math.round(numerin);
          while(j < redondo){
            fila.push(barcode);
            j++;
          }
        }
        auxi = auxi - 1 ;
        aux.push(fila);
        j = 0;
        i++;

      }
      setBarcode(aux);
      setShow(true);
      */
    var canvas = document.createElement("canvas");
    var barcode;
    JsBarcode(canvas, content.data[1]);
    barcode = canvas.toDataURL();
    let etiquetas = JSON.parse(localStorage.getItem("etiquetas"));
    let descripcion = content.data[2];
    let codigo = content.data[1];
    if (etiquetas == null) {
      let aux = { barcode, cantidadEtiquetas, descripcion, codigo };
      localStorage.setItem("etiquetas", JSON.stringify([aux]));
      toast.success(<div>Etiqueta Agreada correctamente</div>, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
    } else {
      let auxi = [];
      let esta = false;
      etiquetas?.forEach(function (elemento) {
        if (elemento.barcode === barcode) {
          elemento.cantidadEtiquetas =
            parseFloat(cantidadEtiquetas) +
            parseFloat(elemento.cantidadEtiquetas);
          auxi.push(elemento);
          esta = true;
        } else {
          auxi.push(elemento);
        }
      });
      if (!esta) {
        auxi.push({ barcode, cantidadEtiquetas, descripcion, codigo });
      }
      localStorage.removeItem("etiquetas");
      localStorage.setItem("etiquetas", JSON.stringify(auxi));
      toast.success(<div>Etiqueta Agreada correctamente</div>, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
    }
    //setShow(true);
  };

  const cerrarModal = () => {
    setShow(false);
  };

  // <Col sm={8} style={{"max-height": "25%", "max-width" : "25%"}}>
  if (content.data !== undefined && image !== undefined) {
    return (
      <Container flex>
        <Row className="justify-content-md-center">
          <Col sm={8}>
            <MDBCarousel showControls showIndicators dark>
              <MDBCarouselInner>
                {image !== "/public/Gris.png"
                  ? image.map((image, id) =>
                      id === 0 ? (
                        <MDBCarouselItem className="active">
                          <MDBCarouselElement src={BASEURL + image} />
                        </MDBCarouselItem>
                      ) : (
                        <MDBCarouselItem>
                          <MDBCarouselElement src={BASEURL + image} />
                        </MDBCarouselItem>
                      )
                    )
                  : console.log("No tiene foto")}
              </MDBCarouselInner>
            </MDBCarousel>
          </Col>
          <Col sm={4}>
            <MDBCardText>
              Código de Artículo:<b>{content.data[1]}</b>
            </MDBCardText>
            <MDBCardText>Código de Barras:</MDBCardText>
            <MDBCardText>
              <b>{content.data[3]}</b>
            </MDBCardText>
            <MDBCardText>
              Precio Costo: <b>${content.data[5]}</b>
            </MDBCardText>
            <MDBCardText>
              Formato: <b>{content.data[7]}</b>
            </MDBCardText>
            <MDBCardText>
              Fecha Precio: <b>{content.data[6]}</b>
            </MDBCardText>
            <MDBCol md="10"></MDBCol>
          </Col>

          <Col>
            <MDBCard
              style={{
                marginTop: "20px",
                margin: "0 0 25px",
                boxShadow: "2px 2px 2px rgb(255, 136, 20)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6>Imprimir Codigos de Barras</h6>
              <MDBInputGroup className="mb-3" textBefore="X">
                <MDBInput
                  id="cantidadEtiquetas"
                  type="text"
                  pattern="[0-9]+([\.][0-9]{1,2})?"
                  min="0"
                  lang="ar"
                  style={{ maxWidth: "180px" }}
                  onChange={(e) => {
                    if (
                      parseFloat(e.target.value) < 0 ||
                      isNaN(parseFloat(e.target.value))
                    ) {
                      e.target.value = "";
                      setCantidadEtiquetas(1);
                    } else {
                      /* Inicio Admitir Un Solo Punto */
                      let str = e.target.value.replace(/[^0-9\.]+/g, "");
                      e.target.value = str;
                      if (e.target.value.includes(".")) {
                        let count = e.target.value.match(/\./g).length;
                        if (count > 1)
                          e.target.value = e.target.value.slice(0, -1);
                      }
                      /* Fin Admitir Un Solo Punto */
                      setCantidadEtiquetas(e.target.value);
                    }
                  }}
                  placeholder={cantidadEtiquetas}
                />
                <Button variant="info" onClick={() => imprimirCodigoBarras()}>
                  Agregar
                </Button>
              </MDBInputGroup>
            </MDBCard>
            <MDBCard
              style={{
                marginTop: "20px",
                margin: "0 0 25px",
                boxShadow: "2px 2px 2px rgb(255, 136, 20)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6>Calculo de Ganancia</h6>
              <p>{"Ganancia Predeterminada: " + ganancia + "%"}</p>
              <Row>
                <MDBInputGroup className="mb-3" textAfter="%">
                  <MDBInput
                    id="ganancia"
                    type="text"
                    pattern="[0-9]+([\.][0-9]{1,2})?"
                    min="0"
                    style={{ maxWidth: "175px" }}
                    onChange={(e) => {
                      if (
                        parseFloat(e.target.value) < 0 ||
                        isNaN(parseFloat(e.target.value))
                      ) {
                        let resultado = 0;
                        if (parseFloat(e.target.value) > 100) {
                          let calculin =
                            parseFloat(content.data[5]) /
                            (100 / parseFloat(ganancia));
                          resultado = calculin + parseFloat(content.data[5]);
                        } else {
                          let porcentaje = parseFloat(ganancia) / 100 + 1;
                          resultado =
                            parseFloat(porcentaje) *
                            parseFloat(content.data[5]);
                        }
                        e.target.value = "";
                        setResultado(resultado);
                        setTotalFinal(
                          parseFloat(cantidad) * parseFloat(resultado)
                        );
                      } else {
                        //console.log(e.target.value);
                        /*
                      100/x=100/200
                      (100/x)*x=(100/200)*x
                      100=0.5*x
                      100/0.5=x
                      200=x
                      x=200*/
                        let resultado = 0;
                        /* Inicio Admitir Un Solo Punto */
                        let str = e.target.value.replace(/[^0-9\.]+/g, "");
                        e.target.value = str;
                        if (e.target.value.includes(".")) {
                          let count = e.target.value.match(/\./g).length;
                          if (count > 1)
                            e.target.value = e.target.value.slice(0, -1);
                        }
                        /* Fin Admitir Un Solo Punto */
                        if (parseFloat(e.target.value) > 100) {
                          let calculin =
                            parseFloat(content.data[5]) /
                            (100 / parseFloat(e.target.value));
                          resultado = calculin + parseFloat(content.data[5]);
                        } else {
                          let porcentaje = parseFloat(e.target.value) / 100 + 1;
                          resultado =
                            parseFloat(porcentaje) *
                            parseFloat(content.data[5]);
                        }
                        //console.log("Cuenta: " + resultado);
                        if (isNaN(resultado)) {
                          resultado = 0;
                        }
                        if (switchChecked) {
                          resultado = resultado / parseFloat(formatoFiltrado);
                        }
                        setResultado(resultado);
                        setTotalFinal(
                          parseFloat(cantidad) * parseFloat(resultado)
                        );
                      }
                    }}
                  />
                </MDBInputGroup>
              </Row>

              <MDBCardText>
                <Row xs="auto">
                  <Col>Precio Unitario</Col>
                  <Col>
                    {formato !== undefined ? (
                      <Switch
                        onChange={handleSwitchChange}
                        checked={switchChecked}
                        className="react-switch"
                        id="normal-switch"
                      />
                    ) : (
                      console.log("Sin formato")
                    )}
                  </Col>
                </Row>
              </MDBCardText>
              <MDBInputGroup className="mb-3">
                <h6
                  style={{ visibility: unidadVisible ? "visible" : "hidden" }}
                >
                  Unidad: {formatoFiltrado}
                </h6>
              </MDBInputGroup>
              <h6>Precio Final</h6>
              <Row>
                <MDBInputGroup className="mb-3" textBefore="$">
                  <MDBInput
                    placeholder="Precio Final"
                    id="preciofinal"
                    type="text"
                    readonly
                    value={resultado.toFixed(2)}
                    style={{ maxWidth: "180px" }}
                  />
                </MDBInputGroup>
              </Row>
              <h6>Cantidad</h6>
              <Row>
                <MDBInputGroup className="mb-3" textBefore="X">
                  <MDBInput
                    id="cantidad"
                    type="text"
                    pattern="[0-9]+([\.][0-9]{1,2})?"
                    min="0"
                    lang="ar"
                    style={{ maxWidth: "180px" }}
                    onChange={(e) => {
                      if (
                        parseFloat(e.target.value) < 0 ||
                        isNaN(parseFloat(e.target.value))
                      ) {
                        e.target.value = "";
                        setCantidad(1);
                        setTotalFinal(resultado);
                      } else {
                        /* Inicio Admitir Un Solo Punto */
                        let str = e.target.value.replace(/[^0-9\.]+/g, "");
                        e.target.value = str;
                        if (e.target.value.includes(".")) {
                          let count = e.target.value.match(/\./g).length;
                          if (count > 1)
                            e.target.value = e.target.value.slice(0, -1);
                        }
                        /* Fin Admitir Un Solo Punto */
                        setCantidad(e.target.value);
                        setTotalFinal(parseFloat(e.target.value) * resultado);
                      }
                    }}
                    placeholder={cantidad}
                  />
                </MDBInputGroup>
              </Row>
              <h6>Total</h6>
              <Row>
                <MDBInputGroup className="mb-3" textBefore="$">
                  <MDBInput
                    id="totalFinal"
                    type="number"
                    min="0"
                    placeholder="Total Final"
                    readOnly
                    value={totalFinal.toFixed(2)}
                    style={{ maxWidth: "180px" }}
                  />
                </MDBInputGroup>
              </Row>
            </MDBCard>
            <MDBCard
              style={{
                marginTop: "20px",
                margin: "0 0 25px",
                boxShadow: "2px 2px 2px rgb(255, 136, 20)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h6>Agregar Al Carrito</h6>
              <Row>
                <MDBInputGroup className="mb-3">
                  <textarea
                    id="observacion"
                    placeholder="Observación sobre el producto (Opcional)"
                    rows="5"
                    cols="27"
                    value={observacionElegido}
                    onChange={(e) => {
                      let elegido = items.findIndex(
                        (element) => element.id === rowData.children[0]
                      );
                      //if(elegido!==-1)
                      setObservacionElegido(e.target.value);
                    }}
                    onBlur={(e) => {
                      let elegido = items.findIndex(
                        (element) => element.id === rowData.children[0]
                      );
                      if (elegido !== -1) {
                        items[elegido].observacion = observacionElegido;
                        updateItemQuantity(
                          rowData.children[0],
                          parseFloat(items[elegido].quantity)
                        );
                      }
                    }}
                  />
                </MDBInputGroup>
              </Row>
              <Row>
                <Col>
                  <h6>Ingrese la cantidad de items</h6>
                  <h6>a agregar al carrito</h6>
                  <MDBInputGroup className="mb-3" textBefore="X">
                    <MDBInput
                      id="cantidadItem"
                      type="number"
                      min="0"
                      lang="ar"
                      onChange={(event, newValue) => {}}
                      onKeyUp={(event) => {
                        setCantidadIngresada(event.target.value);
                        if (isRunning) {
                          const time = new Date();
                          time.setSeconds(time.getSeconds() + 0.5);
                          restart(time);
                        } else {
                          const time = new Date();
                          time.setSeconds(time.getSeconds() + 0.5);
                          start(time);
                        }
                      }}
                      style={{ maxWidth: "180px", minHeight: "44px" }}
                    />
                  </MDBInputGroup>
                  {elegido !== "" ? (
                    <h6>
                      <h6>Hay {elegido.quantity} items en el carrito </h6>
                      <h6>de este artículo.</h6>
                    </h6>
                  ) : (
                    console.log("No está")
                  )}
                </Col>
              </Row>
            </MDBCard>
          </Col>
        </Row>
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
        >
          <Modal.Header>
            <Modal.Title>{tituloModal}</Modal.Title>
            <Button variant="outline-danger" onClick={() => cerrarModal()}>
              X
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Row className="d-flex justify-content-center">
              <PDFViewer width="1000" height="600" className="app">
                <CodigosDeBarra
                  barcode={barcode}
                  cantidad={cantidadEtiquetas}
                  nombre={content.data[2]}
                />
              </PDFViewer>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={() => cerrarModal()}>
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  } else {
    return (
      <div className="container">
        <header className="jumbotron">
          <div className={`d-flex flex-column justify-content-center`}></div>
        </header>
      </div>
    );
  }
};

export default Card;
