import React, { useState, useEffect, useRef } from "react";

import ArticuloService from "../services/articulo.service";

import { useMediaQuery } from "react-responsive";

import Lottie from "react-lottie";

import Camera from "./cameraHandler/index";
import logo from "../assets/iconCamara32.png";

import { Container, Row, Col } from "react-bootstrap/";

import * as lapiz from "../assets/reloj.json";

import { Modal, Button, Image } from "react-bootstrap";

import MUIDataTable from "mui-datatables";

import { EraserFill } from "react-bootstrap-icons";

import ModalControlFotosMobile from "./ModalControlFotosMobile";

const ControlFotos = () => {
  const [content, setContent] = useState({ arts: [] });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [loadingData, setLoadingData] = useState(false);
  const [search, setSearch] = useState("");

  const containerRef = useRef(null);
  const [auxTextSearch, setAuxTextSearch] = useState("");
  const [show, setShow] = useState(false);

  // Modal Camara//
  const [tituloModalCamara, setTituloModalCamara] = useState(
    "Escanear Código de Barras"
  );
  const [fullscreenCamara, setFullscreenCamara] = useState(true);
  const [showCamara, setShowCamara] = useState(false);
  // Fin Modal Camara//

  // Modal //
  const [tituloModal, setTituloModal] = useState("");
  const [rowGuardada, setRowGuardada] = useState("");
  // Fin Modal //

  const [fullscreen, setFullscreen] = useState(true);

  useEffect(() => {
    ArticuloService.getTodosAdmin().then(
      (response) => {
        console.log(response.data);
        //response.data.map((articulo) => console.log(articulo));
        setContent({ arts: response.data });
        setLoadingData(true);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
        setLoadingData(true);
      }
    );
  }, []);

  const columns = [
    {
      name: "articulos_id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "codigo",
      label: "Código",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
    {
      name: "descri",
      label: "Descripción",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
    { name: "codi_barra", label: "Barras" },
    { name: "IVA", label: "IVA" },
    { name: "PRE_VTA", label: "Precio Venta" },
    {
      name: "FEC_OBSERV",
      label: "Fecha Precio",
      options: {
        display: "excluded",
      },
    },
    { name: "FORMATO", label: "FORMATO" },
  ];

  const lapízOptions = {
    loop: true,
    autoplay: true,
    animationData: lapiz.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const options = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Artículos",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    download: false,
    print: false,
    searchText: search,
    fixedHeader: true,
    fixedSelectColumn: true,
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
      if(expandedRows.data.length>1){
        let original = expandedRows.data;
        let aux = expandedRows.data.slice(dataIndex,-1);
        expandedRows.data = aux;
        setMostradas(aux);
        return true;
      }
      else{
        
      }
      //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
      return true;
      */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData) => {
      /*
      if(isTabletOrMobile){
        let textoSearch = containerRef.current.state.searchText;
        console.log(rowData);
        setTituloModal(rowData[2]);
        setRowGuardada(rowData);
        setAuxTextSearch(textoSearch);
        setShow(true);
      }
      */
      let textoSearch = containerRef.current.state.searchText;
      console.log(rowData);
      setTituloModal(rowData[2]);
      setRowGuardada(rowData);
      setAuxTextSearch(textoSearch);
      setShow(true);
    },
    /*
    renderExpandableRow: (rowData, rowMeta) => {
      if(!isTabletOrMobile){
        const colSpan = rowData.length + 1;
        return (
          <TableRow>
            <TableCell colSpan={colSpan} >
              <Card>{rowData}</Card>
            </TableCell>
          </TableRow>
        );
      }
    },
    */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const limpiarSearchBox = () => {
    //TODO Mejorar esto la segunda vez no lo borra
    //containerRef.current.state.searchText = "";
    setSearch("");
    setAuxTextSearch("");
  };

  const actualizarPadre = (devuelto) => {
    setSearch(devuelto);
    setShowCamara(false);
  };

  const cerrarModal = () => {
    setSearch(auxTextSearch);
    setShow(false);
  };

  return (
    <Container fluid>
      <div>
        <Row className="d-flex justify-content-center">
          <Col>
            {!loadingData ? (
              <Lottie options={lapízOptions} height={200} width={200} />
            ) : (
              <div>
                {isTabletOrMobile ? (
                  <Row className="d-flex justify-content-center">
                    <Col>
                      <Button
                        variant="light"
                        onClick={() => setShowCamara(true)}
                      >
                        <Image src={logo}></Image> Escanear
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant="light"
                        onClick={() => limpiarSearchBox()}
                      >
                        <EraserFill size={30} /> Limpiar Campos
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  console.log("")
                )}
                <Row className="d-flex justify-content-center"></Row>
                <Row className="d-flex justify-content-center">
                  <h6> </h6>
                </Row>
                <MUIDataTable
                  ref={containerRef}
                  title={"Lista de Artículos"}
                  data={content.arts}
                  columns={columns}
                  options={options}
                  id="tabla"
                ></MUIDataTable>
              </div>
            )}
          </Col>
        </Row>
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
        >
          <Modal.Header>
            <Modal.Title>{tituloModal}</Modal.Title>
            <Button variant="outline-danger" onClick={() => cerrarModal()}>
              X
            </Button>
          </Modal.Header>
          <Modal.Body>
            <ModalControlFotosMobile articulo={rowGuardada}>
              {" "}
            </ModalControlFotosMobile>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={() => cerrarModal()}>
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showCamara}
          fullscreen={fullscreenCamara}
          onHide={() => setShowCamara(false)}
        >
          <Modal.Header>
            <Modal.Title>{tituloModalCamara}</Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => setShowCamara(false)}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Row className="d-flex justify-content-center">
              <Col>
                <Camera actualizarPadre={actualizarPadre}></Camera>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={() => setShowCamara(false)}
            >
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Container>
  );
};

//TODO ver que limpie el textbox de búsqueda

export default ControlFotos;
