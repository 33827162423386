import React, {Fragment} from 'react';
import {Image, View, StyleSheet, Text } from '@react-pdf/renderer';
const borderColor = '#ff8728';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#ff8728',
        borderBottomWidth: 0,
        alignItems: 'center',
        fontStyle: 'bold',
        paddingTop : '4px'
    },
    etiqueta: {
        width: 74,
        height: 56,
        marginLeft: '2px',
        marginRight: '2px',
        display: "inline"
    },
    titulo:{
        fontSize: 6,
        textAlign: 'center',
        textTransform: 'uppercase',
        marginLeft: '2px',
        marginRight: '2px',
        marginBottom: '-7px'
    },
    texto:{
        fontSize: 6,
        textAlign: 'center',
        textTransform: 'uppercase',
        marginLeft: '4px',
        marginRight: '2px',
    }
  });

// 
        
const CodigosDeBarrasFila = ({fila}) => {
    
    const rows = fila.map( item => 
        <View>
            <Text style={styles.texto}>{item.descripcion.substr(0, 20)}</Text>
            <Text style={styles.titulo}>José Karam Mayorista</Text>     
            <View style={styles.row}>
                <Image src={item.barra} style={styles.etiqueta}/>
            </View>
        </View>
        
    )
    return (<Fragment>{rows}</Fragment> )
};

export default CodigosDeBarrasFila