import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import TablaCC from "./TablaCC";

import EventBus from "../common/EventBus";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const BoardModerator = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getModeratorBoard().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return (
    <div className="container">
      <h3>Cuentas Corrientes</h3>
      {content !== undefined && content !== "No token provided!" ? (
        <Tabs
          defaultActiveKey="todas"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(k) => console.log("K:" + k)}
        >
          <Tab eventKey="todas" title="Todas">
            <TablaCC>{0}</TablaCC>
          </Tab>
          <Tab eventKey="deudores" title="Deudores">
            <TablaCC>{1}</TablaCC>
          </Tab>
          <Tab eventKey="atrasados" title="Atrasados">
            <TablaCC>{2}</TablaCC>
          </Tab>
        </Tabs>
      ) : (
        console.log("")
      )}
    </div>
  );
};

export default BoardModerator;
