import axios from "axios";
import authHeader from "./auth-header";

require('dotenv').config();

const API_URL = process.env.REACT_APP_URL+"/api/permisos/"; //"http://localhost:8080/api/test/";

const getUsersConPermisos = () => {
  return axios.get(API_URL ,{ headers: authHeader() });
};

const getSearchUserConPermisos = (id) => {
  return axios.get(API_URL + "search/" + id ,{ headers: authHeader() });
};

const updatePermisos = (data) => {
  return axios.put(API_URL, {data} ,{ headers: authHeader() });
};

const updateResetPermisos = (id) => {
  return axios.put(API_URL + id , null, { headers: authHeader() });
};

const UserService = {
    getUsersConPermisos,
    getSearchUserConPermisos,
    updatePermisos,
    updateResetPermisos
};

export default UserService;
