import axios from "axios";
import authHeader from "./auth-header";

require("dotenv").config();

const API_URL = process.env.REACT_APP_URL + "/api/test/"; //"http://localhost:8080/api/test/";
const API_URL2 = process.env.REACT_APP_URL + "/api/";

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};

const resetPasswordLogeado = (password) => {
  let user = JSON.parse(localStorage.getItem("user"));
  return axios.post(
    process.env.REACT_APP_URL + "/api/user/resetPasswordLogeado/" + user.id,
    { password },
    { headers: authHeader() }
  );
};

const getAllUsers = () => {
  return axios.get(API_URL2 + "user", { headers: authHeader() });
};

const activarUsuarioCliente = (data) => {
  return axios.post(
    API_URL2 + "user/activarcliente/",
    { data },
    { headers: authHeader() }
  );
};

const activarUsuarioEmpleado = (data) => {
  return axios.post(
    API_URL2 + "user/activarempleado/",
    { data },
    { headers: authHeader() }
  );
};

const deleteUsuario = (id) => {
  return axios.delete(API_URL2 + "user/" + id, { headers: authHeader() });
};

const resetPasswordDefaultUsuario = (id) => {
  return axios.put(
    API_URL2 + "user/resetpassword/" + id,
    {},
    {
      headers: authHeader(),
    }
  );
};

const desvincularCliente = (id) => {
  return axios.put(
    API_URL2 + "user/desvincularcliente/" + id,
    {},
    {
      headers: authHeader(),
    }
  );
};

const desvincularEmpleado = (id) => {
  return axios.put(
    API_URL2 + "user/desvincularempleado/" + id,
    {},
    {
      headers: authHeader(),
    }
  );
};

const getEliminados = () => {
  return axios.get(API_URL2 + "user/eliminados", { headers: authHeader() });
};

const rescatarUsuarioEliminado = (id) => {
  return axios.put(
    API_URL2 + "user/rescatareliminado/" + id,
    {},
    {
      headers: authHeader(),
    }
  );
};

const UserService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  resetPasswordLogeado,
  getAllUsers,
  activarUsuarioCliente,
  activarUsuarioEmpleado,
  deleteUsuario,
  resetPasswordDefaultUsuario,
  desvincularCliente,
  desvincularEmpleado,
  getEliminados,
  rescatarUsuarioEliminado,
};

export default UserService;
