import React from "react";
import {
  Page,
  Document,
  Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 14,
  },
  titulo: {
    fontSize: 20,
    fontWeight: "Bold",
    padding: 4,
  },
  subtitulo: {
    fontSize: 18,
    fontWeight: "bold",
    textDecoration: "underline",
    padding: 4,
  },
  tituloMediano: {
    fontSize: 18,
    fontWeight: "normal",
    padding: 4,
  },
  dato: {
    fontSize: 24,
    fontWeight: "bold",
  },
  pagos: {
    fontWeight: "normal",
    paddingTop: 4,
  },
  pagosTitulo: {
    fontSize: 14,
  },
  pagoDestacado: {
    fontSize: 18,
    fontWeight: "normal",
  },
  totalPagos: {
    paddingLeft: 220,
  },
  compras: {
    fontWeight: "normal",
    paddingTop: 4,
    paddingBottom: 4,
  },
  tabla: {
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 4,
    padding: 4,
  },
  encabezadoTabla: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#000",
    padding: 4,
  },
  celdaEncabezado: {
    flex: 1,
    fontWeight: "bold",
    textAlign: "center",
  },
  celda: {
    flex: 1,
    textAlign: "center",
  },
  filaTabla: {
    flexDirection: "row",
    padding: 4,
  },
  celdaTabla: {
    flex: 1,
    textAlign: "center",
  },
  tituloPagos: {
    fontSize: 20,
    fontWeight: "Bold",
  },
});

const ResumenDeCajaCerrada = ({
  idCaja,
  fechaDeCierre,
  montoEfectivo,
  montoTransferencias,
  montoCheques,
  montoTarjetas,
  montoTotal,
  observacionCaja,
  nombreEmpleado,
  telefonoEmpleado,
  pagos,
}) => (
  <Document>
    <Page size="A4" style={styles.page} wrap>
      <View>
        <View style={{ padding: 4 }}>
          <Text style={styles.titulo}>Datos Empleado</Text>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.subtitulo}>Nombre:</Text>
              <Text style={styles.titulo}>{nombreEmpleado}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.subtitulo}>Telefono:</Text>
              <Text style={styles.titulo}>{telefonoEmpleado}</Text>
            </View>
          </View>
        </View>
        <View style={{ padding: 4 }}>
          <Text style={styles.titulo}>Datos de la Caja</Text>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.subtitulo}>Número de caja:</Text>
            <Text style={styles.titulo}>{idCaja}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.subtitulo}>Fecha de cierre:</Text>
            <Text style={styles.titulo}>{fechaDeCierre}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.subtitulo}>Monto efectivo:</Text>
            <Text style={styles.titulo}>${montoEfectivo}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.subtitulo}>Monto transferencias:</Text>
            <Text style={styles.titulo}>${montoTransferencias}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.subtitulo}>Monto cheques:</Text>
            <Text style={styles.titulo}>${montoCheques}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.subtitulo}>Monto tarjetas:</Text>
            <Text style={styles.titulo}>${montoTarjetas}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.subtitulo}>Monto total:</Text>
            <Text style={styles.titulo}>${montoTotal}</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={styles.subtitulo}>Observacion:</Text>
            <Text style={styles.titulo}>{observacionCaja}</Text>
          </View>
        </View>
        <View style={{ padding: 4 }}>
          {
            // Si no hay pagos en la ultima fecha no se muestra el titulo
            pagos.length > 0 && (
              <View>
                <Text style={styles.tituloPagos}>Pagos realizados</Text>
                <View style={styles.pagos}>
                  <View style={styles.tabla}>
                    <View style={styles.encabezadoTabla}>
                      <Text style={styles.celdaEncabezado}>Fecha</Text>
                      <Text style={styles.celdaEncabezado}>Tipo</Text>
                      <Text style={styles.celdaEncabezado}>Total</Text>
                      <Text style={styles.celdaEncabezado}>Observaciones</Text>
                    </View>
                    {pagos.map((pago, index) => (
                      <View key={index} style={styles.filaTabla}>
                        <Text style={styles.celda}>
                          {dayjs(pago.Fecha).format("DD/MM/YYYY HH:mm")}
                        </Text>
                        <Text style={styles.celda}>{pago.Tipo}</Text>
                        <Text style={styles.celda}>${pago.Total}</Text>
                        <Text style={styles.celda}>{pago.Observaciones}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
            )
          }
        </View>
      </View>
    </Page>
  </Document>
);

export default ResumenDeCajaCerrada;
