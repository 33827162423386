import React, { useState, useEffect, useRef } from "react";

import { Container, Row, Col } from "react-bootstrap/";
import EventBus from "../common/EventBus";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useMediaQuery } from "react-responsive";
import { MDBInputGroup, MDBInput } from "mdb-react-ui-kit";
import { Trash3Fill, PencilFill, EyeFill } from "react-bootstrap-icons";
import { Button, Alert, Modal } from "react-bootstrap/";
import CustomFooter from "./customFooter";
import TextareaAutosize from "react-textarea-autosize";

import { toast } from "react-toastify";

import PedidoService from "../services/pedido.service";

import { useCart } from "react-use-cart";

const Carrito = () => {
  let navigate = useNavigate();
  const [content, setContent] = useState("");
  // Modal //
  const [tituloModal, setTituloModal] = useState("");
  const [rowGuardada, setRowGuardada] = useState("");
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [elegido, setElegido] = useState(false);
  const [texto, setTexto] = useState("");
  // Fin Modal //
  // Modal Resultado //
  const [tituloModalResultado, setTituloModalResultado] = useState("");
  const [fullscreenResultado, setFullscreenResultado] = useState(true);
  const [textoResultado, setTextoResultado] = useState("");
  const [showResultado, setShowResultado] = useState(false);
  // Fin Modal Resultado //
  const {
    items,
    updateItemQuantity,
    removeItem,
    cartTotal,
    updateItem,
    totalItems,
    emptyCart,
  } = useCart();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const containerRef = useRef(null);

  const [auxInput, setAuxInput] = useState("");
  const [auxItemElegido, setItemElegido] = useState("");

  // Modal Resultado //
  const [tituloModalCantidadCero, setTituloModalCantidadCero] = useState("");
  const [fullscreenCantidadCero, setFullscreenCantidadCero] = useState(true);
  const [textoCantidadCero, setTextoCantidadCero] = useState("");
  const [showCantidadCero, setShowCantidadCero] = useState(false);
  // Fin Modal Resultado //

  // Modal Card//
  const [tituloModalCard, setTituloModalCard] = useState("Art");
  const [fullscreenCard, setFullscreenCard] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [dataArt, setDataArt] = useState();
  // Fin Modal Card//

  // Modal Celu//
  const [tituloModalCelu, setTituloModalCelu] = useState("Art");
  const [fullscreenCelu, setFullscreenCelu] = useState(true);
  const [showCelu, setShowCelu] = useState(false);
  const [dataIdElegido, setDataIdElegido] = useState();
  const [dataCantidadElegido, setDataCantidadElegido] = useState();
  // Fin Modal Celu//

  const cerrarModal = () => {
    setShow(false);
  };
  const cerrarModalResultado = () => {
    emptyCart();
    setShowResultado(false);
    navigate("/articulos");
  };
  const cerrarModalCantidadCero = () => {
    setShowCantidadCero(false);
  };
  useEffect(() => {
    console.log("Items en el carrito");
    console.log(items);
  }, []);

  const cerrarModalEliminaArticulo = () => {
    let elegido = auxItemElegido;
    removeItem(elegido);
    setShowCantidadCero(false);
    if (showCelu) {
      setShowCelu(false);
    }
  };
  let columns;
  if (isTabletOrMobile) {
    columns = [
      {
        name: "id",
        label: "ID",
        options: {
          display: "excluded",
        },
      },
      {
        name: "name",
        label: "Descripción",
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
      {
        name: "price",
        label: "P/U",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return nf.format(value);
          },
        },
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
      {
        name: "quantity",
        label: "Cantidad",
        options: {
          filter: true,
        },
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
      {
        name: "price",
        label: "Total Item",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            let aux = parseFloat(tableMeta.rowData[3]) * parseFloat(value);
            return nf.format(aux);
          },
        },
      },
    ];
  } else {
    columns = [
      {
        name: "id",
        label: "ID",
        options: {
          display: "excluded",
        },
      },
      {
        name: "name",
        label: "Descripción",
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
      {
        name: "observacion",
        label: "Observación",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <MDBInputGroup className="mb-3">
                <Button
                  variant="light"
                  onClick={() => {
                    let id = tableMeta.rowData[0];
                    let elegido = items.find((element) => element.id === id);
                    setElegido(elegido);
                    setTexto(elegido.observacion);
                    setTituloModal("Observación");
                    setShow(true);
                  }}
                >
                  <EyeFill size={30} />
                </Button>
              </MDBInputGroup>
            );
          },
        },
      },
      {
        name: "price",
        label: "P/U",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            return nf.format(value);
          },
        },
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
      {
        name: "quantity",
        label: "Cantidad",
        options: {
          filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <MDBInputGroup className="mb-3">
                <MDBInput
                  id="cantidadItem"
                  type="number"
                  value={value}
                  onChange={(event, newValue) => {
                    setAuxInput(event.target.value);
                    if (parseFloat(event.target.value) > 0) {
                      let id = tableMeta.rowData[0];
                      let elegido = items.findIndex(
                        (element) => element.id === id
                      );
                      items[elegido].quantity = event.target.value;
                      updateItemQuantity(id, parseFloat(event.target.value));
                    }
                    if (event.target.value === "0") {
                      let id = tableMeta.rowData[0];
                      let elegido = items.find((element) => element.id === id);
                      setItemElegido(elegido.id);
                      setTituloModalCantidadCero("Eliminar");
                      setTextoCantidadCero(
                        "Desea Eliminar Articulo del Carrito?"
                      );
                      setShowCantidadCero(true);
                    }
                  }}
                  onKeyUp={(event) => {
                    if (event.keyCode === 8) {
                      if (auxInput.length === 0) event.target.select();
                    }
                  }}
                  style={{ maxWidth: "100px", minHeight: "44px" }}
                />
                <Button
                  variant="light"
                  onClick={() => {
                    let id = tableMeta.rowData[0];
                    let elegido = items.find((element) => element.id === id);
                    setItemElegido(elegido.id);
                    setTituloModalCantidadCero("Eliminar");
                    setTextoCantidadCero(
                      "Desea Eliminar Articulo del Carrito?"
                    );
                    setShowCantidadCero(true);
                  }}
                >
                  <Trash3Fill size={30} />
                </Button>
              </MDBInputGroup>
            );
          },
        },
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
      {
        name: "price",
        label: "Total Item",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "ARS",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            let aux = parseFloat(tableMeta.rowData[4]) * parseFloat(value);
            return nf.format(aux);
          },
        },
      },
    ];
  }
  const options = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Artículos",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
      if(expandedRows.data.length>1){
        let original = expandedRows.data;
        let aux = expandedRows.data.slice(dataIndex,-1);
        expandedRows.data = aux;
        setMostradas(aux);
        return true;
      }
      else{
        
      }
      //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
      return true;
      */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData, rowMeta) => {
      if (isTabletOrMobile) {
        let id = rowData[0];
        let elegido = items.find((element) => element.id === id);
        setDataIdElegido(elegido.id);
        setTituloModalCelu(elegido.name);
        setDataCantidadElegido(elegido.quantity);
        setTexto(elegido.observacion);
        setShowCelu(true);
      }
    },
    renderExpandableRow: (rowData, rowMeta) => {
      if (!isTabletOrMobile) {
        const colSpan = rowData.length + 1;
        let id = rowData[0];
        let elegido = items.find((element) => element.id === id);
        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <Row>
                <Col>
                  <p>
                    Precio Unitaro: <b>${elegido.price}</b>
                  </p>
                  <p>
                    Observación en el pedido: <b>{elegido.observacion}</b>
                  </p>
                </Col>
                <Col>
                  <textarea
                    id="observacion"
                    placeholder="Observación"
                    rows="5"
                    cols="27"
                  />
                  <Button
                    onClick={(event, newValue) => {
                      let aux = {
                        observacion:
                          document.getElementById("observacion").value,
                      };
                      console.log(aux);
                      updateItem(elegido.id, aux);
                    }}
                  >
                    {" "}
                    Cambiar Observación
                  </Button>
                </Col>
                <Col center></Col>
              </Row>
            </TableCell>
          </TableRow>
        );
      }
    },

    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
    // TODO Acá ver porqué no cambia la hoja
    /*
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
          precioTotal = {cartTotal}
        />
      );
    },
    */
  };
  const handleMessageChange = (event) => {
    // 👇️ update textarea value
    setTexto(event.target.value);
    console.log(event.target.value);
  };
  const addPedido = () => {
    if (items.length > 0) {
      let pedido = {
        items: items,
        total: cartTotal,
        observaciones: document.getElementById("observacionPedido").value,
      };
      PedidoService.addPedido(pedido).then(
        (response) => {
          console.log("Pedido Realizado Correctamente");
          setTituloModalResultado("Información");
          setTextoResultado("Pedido Generado Correctamente");
          setShowResultado(true);
        },
        (error) => {
          const resMessage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          setTituloModalResultado("Error");
          setTextoResultado("Ocurrió un error: " + error.toString());
          setShowResultado(true);
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    } else {
      toast.error(
        "Carrito Vacío. Agregue artículos al carrito para cerrar el pedido",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const cerrarModalCard = () => {
    setShowCard(false);
  };
  const mostrarModalCard = () => {
    if (items.length > 0) {
      setShowCard(true);
    } else {
      toast.error(
        "Carrito Vacío. Agregue artículos al carrito para cerrar el pedido",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const cerrarModalCelu = () => {
    setShowCelu(false);
  };

  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <Col>
          <MUIDataTable
            ref={containerRef}
            title={"Lista de Artículos"}
            data={items}
            columns={columns}
            options={options}
            id="tabla"
          ></MUIDataTable>
        </Col>
      </Row>
      <Row></Row>
      <div
        className="d-flex justify-content-center"
        style={{ paddingTop: "10px" }}
      >
        {isTabletOrMobile ? (
          <Col>
            <Row className="d-flex justify-content-center">
              <TextareaAutosize
                id="observacionPedido"
                placeholder="Observación General del Pedido"
                style={{ minWidth: 300 }}
              />
            </Row>
            <Row className="d-flex justify-content-center">
              <div className="p-2">
                <Button
                  onClick={mostrarModalCard}
                  style={{ backgroundColor: "#ff8814", borderColor: "#ff8814" }}
                >
                  Cerrar Pedido
                </Button>
              </div>
            </Row>
          </Col>
        ) : (
          <Row className="d-flex justify-content-center">
            <div className="p-2">
              <TextareaAutosize
                id="observacionPedido"
                placeholder="Observación General del Pedido"
                style={{ minWidth: 500 }}
                minRows={2}
              />
            </div>
            <div className="p-2">
              <Button
                onClick={mostrarModalCard}
                style={{ backgroundColor: "#ff8814", borderColor: "#ff8814" }}
              >
                Cerrar Pedido
              </Button>
            </div>
          </Row>
        )}
      </div>
      <Row>
        <Col style={{ paddingTop: "10px" }}>
          <Alert variant="danger" md>
            <Alert.Heading>Información</Alert.Heading>
            <p> Los precios están sujetos a cambios sin previo aviso.</p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col style={{ paddingTop: "10px" }}>
          <Alert variant="danger" md>
            <Alert.Heading>Instrucciones de compra</Alert.Heading>
            <p>
              1. Agregá los productos a tu carrito haciendo click en los
              botones.
            </p>
            <hr />
            <p className="mb-0">
              2. Confirmá el carrito y tu vendedor asignado recibe el pedido en
              el instante.
            </p>
            <hr />
            <p className="mb-0">
              3. Tu vendedor te llama para coordinar la entrega y el pago.
            </p>
          </Alert>
        </Col>
      </Row>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{tituloModal}</Modal.Title>
          <Button variant="outline-danger" onClick={() => cerrarModal()}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <Col>
              <textarea
                id="observacion"
                placeholder="Observación"
                rows="5"
                cols="60"
                value={texto}
                onChange={handleMessageChange}
                key={items.length}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(event, newValue) => {
              let aux = {
                observacion: document.getElementById("observacion").value,
              };
              console.log(aux);
              updateItem(elegido.id, aux);
              cerrarModal();
              toast.success("Observación cambiada correctamente", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }}
          >
            {" "}
            Cambiar Observación
          </Button>
          <Button variant="outline-danger" onClick={() => cerrarModal()}>
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showResultado}
        fullscreen={fullscreen}
        onHide={() => setShowResultado(false)}
      >
        <Modal.Header>
          <Modal.Title>{tituloModalResultado}</Modal.Title>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalResultado()}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>{textoResultado}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalResultado()}
          >
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCantidadCero}
        fullscreen={fullscreen}
        onHide={() => setShowCantidadCero(false)}
      >
        <Modal.Header>
          <Modal.Title>{tituloModalCantidadCero}</Modal.Title>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalResultado()}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>{textoCantidadCero}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalEliminaArticulo()}
          >
            Eliminar Articulo
          </Button>
          <Button
            variant="outline-success"
            onClick={() => cerrarModalCantidadCero()}
          >
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCard}
        fullscreen={fullscreenCard}
        onHide={() => cerrarModalCard()}
      >
        <Modal.Header>
          <Modal.Title>Alerta</Modal.Title>
          <Button variant="outline-danger" onClick={() => cerrarModalCard()}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <Col>Desea Cerrar el Pedido?</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={() => addPedido()}>
            Cerrar Pedido
          </Button>
          <Button variant="success" onClick={() => cerrarModalCard()}>
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCelu}
        fullscreen={fullscreenCelu}
        onHide={() => cerrarModalCelu()}
      >
        <Modal.Header>
          <Modal.Title>{tituloModalCelu}</Modal.Title>
          <Button variant="outline-danger" onClick={() => cerrarModalCelu()}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ paddingLeft: "10px" }}>
            {dataIdElegido != undefined ? (
              <Col>
                Cantidad Actual en el Carrito: {dataCantidadElegido}
                <MDBInputGroup className="mb-3">
                  <MDBInput
                    id="cantidadItem"
                    type="number"
                    value={dataCantidadElegido}
                    onChange={(event, newValue) => {
                      setAuxInput(event.target.value);
                      if (parseFloat(event.target.value) > 0) {
                        let id = dataIdElegido;
                        let elegido = items.findIndex(
                          (element) => element.id === id
                        );
                        items[elegido].quantity = event.target.value;
                        updateItemQuantity(id, parseFloat(event.target.value));
                        setDataCantidadElegido(parseFloat(event.target.value));
                      }
                      if (event.target.value === "0") {
                        let id = dataIdElegido;
                        let elegido = items.find(
                          (element) => element.id === id
                        );
                        setItemElegido(elegido.id);
                        setTituloModalCantidadCero("Eliminar");
                        setTextoCantidadCero(
                          "Desea Eliminar Articulo del Carrito?"
                        );
                        setShowCantidadCero(true);
                      }
                    }}
                    onKeyUp={(event) => {
                      if (event.keyCode === 8) {
                        if (auxInput.length === 0) event.target.select();
                      }
                    }}
                    style={{ maxWidth: "100px", minHeight: "44px" }}
                  />
                  <Button
                    variant="light"
                    onClick={() => {
                      let id = dataIdElegido;
                      let elegido = items.find((element) => element.id === id);
                      setItemElegido(elegido.id);
                      setTituloModalCantidadCero("Eliminar");
                      setTextoCantidadCero(
                        "Desea Eliminar Articulo del Carrito?"
                      );
                      setShowCantidadCero(true);
                    }}
                  >
                    <Trash3Fill size={30} />
                  </Button>
                </MDBInputGroup>
                <textarea
                  id="observacion"
                  placeholder="Observación"
                  rows="5"
                  cols="36"
                  value={texto}
                  onChange={handleMessageChange}
                  key={items.length}
                />
                <Button
                  onClick={(event, newValue) => {
                    let aux = {
                      observacion: document.getElementById("observacion").value,
                    };
                    console.log(aux);
                    updateItem(dataIdElegido, aux);
                    toast.success("Observación cambiada correctamente", {
                      position: "top-center",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }}
                >
                  {" "}
                  Cambiar Observación
                </Button>
              </Col>
            ) : (
              console.log("")
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => cerrarModalCelu()}>
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Carrito;
