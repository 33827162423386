import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Image,
} from "react-bootstrap/";
import { useMediaQuery } from "react-responsive";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MUIDataTable from "mui-datatables";
import { MDBInputGroup, MDBInput } from "mdb-react-ui-kit";
import {
  Search,
  FileEarmarkArrowDownFill,
  Trash3Fill,
} from "react-bootstrap-icons";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Fragment,
} from "@react-pdf/renderer";
import CodigosDeBarra from "./reports/CodigosDeBarra";
import EventBus from "../common/EventBus";

import { toast } from "react-toastify";
import CCService from "../services/cc.service";
import { useSelector, useDispatch } from "react-redux";
import { update } from "../redux/userSlice";
import "react-datepicker/dist/react-datepicker.css";

var JsBarcode = require("jsbarcode");
// Canvas v1
var Canvas = require("canvas");

const ImprimirEtiquetas = () => {
  const [content, setContent] = useState("");
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const containerRef = useRef(null);
  const [items, setItems] = useState([]);
  const [listaItems, setListaItems] = useState([]);
  // Modal //
  const [tituloModal, setTituloModal] = useState("Imprimir Codigos de Barra");
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  // Fin Modal //
  // Modal Pregunta//
  const [tituloModalPregunta, setTituloModalPregunta] = useState("Atención");
  const [fullscreenPregunta, setFullscreenPregunta] = useState(true);
  const [showPregunta, setShowPregunta] = useState(false);
  const [auxElminar, setAuxEliminar] = useState("");
  // Fin Modal Pregunta//
  // Modal Pregunta Eliminar Todo//
  const [tituloModalPreguntaEliminarTodo, setTituloModalPreguntaEliminarTodo] =
    useState("Atención");
  const [fullscreenPreguntaEliminarTodo, setFullscreenPreguntaEliminarTodo] =
    useState(true);
  const [showPreguntaEliminarTodo, setShowPreguntaEliminarTodo] =
    useState(false);
  const [auxElminarEliminarTodo, setAuxEliminarEliminarTodo] = useState("");
  // Fin Modal Pregunta Eliminar Todo//
  // Modal Pregunta Eliminar Con Cero//
  const [
    tituloModalPreguntaEliminarConCero,
    setTituloModalPreguntaEliminarConCero,
  ] = useState("Atención");
  const [
    fullscreenPreguntaEliminarConCero,
    setFullscreenPreguntaEliminarConCero,
  ] = useState(true);
  const [showPreguntaEliminarConCero, setShowPreguntaEliminarConCero] =
    useState(false);
  const [auxElminarEliminarConCero, setAuxEliminarEliminarConCero] =
    useState("");
  const [auxBorrar, setAuxBorrar] = useState("");
  // Fin Modal Pregunta Eliminar Con Cero//
  const dispatch = useDispatch();
  useEffect(() => {
    /* Inicio Actualizar Saldo Cliente */
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.cuenta_corriente != null) {
      CCService.getSaldo(user.cuenta_corriente.clienteIdCliente).then(
        (response) => {
          user.cuenta_corriente.saldo = response.data.saldo;
          localStorage.setItem("user", JSON.stringify(user));
          dispatch(update());
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setContent(_content);
          //setLoadingData(true);
        }
      );
    }
    /* Fin Actualizar Saldo Cliente */
    let items = JSON.parse(localStorage.getItem("etiquetas"));
    if (items != null) setItems(JSON.parse(localStorage.getItem("etiquetas")));
  }, []);
  const columns = [
    {
      name: "barcode",
      label: "Código de Barras",
      options: {
        //display: 'excluded'
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
              src={value}
              alt="Codigo de Barras"
              style={{ width: 120, height: 100, marginLeft: "24px" }}
            />
          );
        },
      },
    },
    {
      name: "descripcion",
      label: "Descripción",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
    {
      name: "cantidadEtiquetas",
      label: "Cantidad",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <MDBInputGroup className="mb-3">
              <MDBInput
                id="cantidadItem"
                type="number"
                value={value}
                onChange={(event, newValue) => {
                  let cambio = false;
                  console.log(event.target.value);
                  let listaAux = [];
                  items.forEach(function (elemento) {
                    if (elemento.codigo === tableMeta.rowData[3]) {
                      if (parseFloat(event.target.value) > 0) {
                        elemento.cantidadEtiquetas = parseFloat(
                          event.target.value
                        );
                        listaAux.push(elemento);
                        cambio = true;
                      } else {
                        setAuxBorrar(elemento.codigo);
                        setShowPreguntaEliminarConCero(true);
                        listaAux.push(elemento);
                      }
                    } else {
                      listaAux.push(elemento);
                    }
                  });
                  localStorage.removeItem("etiquetas");
                  localStorage.setItem("etiquetas", JSON.stringify(listaAux));
                  if (listaAux != null) {
                    setItems(JSON.parse(localStorage.getItem("etiquetas")));
                  }
                  if (cambio) {
                    toast.success(
                      <div>Etiqueta modificada correctamente</div>,
                      {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        whiteSpace: "pre-line",
                      }
                    );
                  }
                  /*
            setAuxInput(event.target.value);
            if(parseFloat ( event.target.value)>0 ){
              let id = tableMeta.rowData[0];
              let elegido = items.findIndex(element => element.id === id);
              items[elegido].quantity = event.target.value;
              updateItemQuantity (id, parseFloat(event.target.value));
            }
            if(event.target.value==="0"){
              let id = tableMeta.rowData[0];
              let elegido = items.find(element => element.id === id);
              setItemElegido(elegido.id);
              setTituloModalCantidadCero("Eliminar")
              setTextoCantidadCero("Desea Eliminar Articulo del Carrito?");
              setShowCantidadCero(true);
            }
            */
                }}
                onKeyUp={(event) => {
                  /*
            if(event.keyCode===8){
              if(auxInput.length===0)
              event.target.select()
            }

            */
                }}
                style={{ maxWidth: "100px", minHeight: "44px" }}
              />
              <Button
                variant="light"
                onClick={() => {
                  setAuxEliminar(tableMeta.rowData[3]);
                  setShowPregunta(true);
                }}
              >
                <Trash3Fill size={30} />
              </Button>
            </MDBInputGroup>
          );
        },
      },
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
    {
      name: "codigo",
      label: "Codigo",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      options: {
        display: "excluded",
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Etiqueta",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
      if(expandedRows.data.length>1){
        let original = expandedRows.data;
        let aux = expandedRows.data.slice(dataIndex,-1);
        expandedRows.data = aux;
        setMostradas(aux);
        return true;
      }
      else{
        
      }
      //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
      return true;
      */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData, rowMeta) => {
      if (isTabletOrMobile) {
      }
    },
    renderExpandableRow: (rowData, rowMeta) => {
      if (!isTabletOrMobile) {
        const colSpan = rowData.length + 1;
        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <Row>
                <Col>wasa</Col>
                <Col>wasa2</Col>
              </Row>
            </TableCell>
          </TableRow>
        );
      }
    },

    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const imprimirCodigoBarras = () => {
    let listaItems = items;
    let cantidadEtiquetas = 0;
    let aux = [];
    let i = 0;
    let j = 0;
    let listaAux = [];
    listaItems.forEach(function (elemento) {
      cantidadEtiquetas =
        parseFloat(cantidadEtiquetas) + parseFloat(elemento.cantidadEtiquetas);
      let cant = elemento.cantidadEtiquetas;
      let o = 0;
      while (o < cant) {
        listaAux.push(elemento);
        o++;
      }
    });

    const tope = 6;
    let fila = [];
    listaAux?.forEach(function (elemento, index) {
      if (i < tope) {
        let barra = elemento.barcode;
        let descripcion = elemento.descripcion;
        fila.push({ barra, descripcion });
        i++;
        if (listaAux.length - 1 === index) {
          aux.push(fila);
        }
      } else {
        aux.push(fila);
        fila = [];
        let barra = elemento.barcode;
        let descripcion = elemento.descripcion;
        fila.push({ barra, descripcion });
        i = 1;
        if (listaAux.length - 1 === index) {
          aux.push(fila);
        }
      }
    });
    /*
    while(i< auxCantidadEtiquetas){
        let fila = [];
        if(auxi > 1){
            while(j<tope){
                let barra = listaAux[i].barcode;
                let descripcion = listaAux[i].descripcion;
                fila.push({barra, descripcion});
                j++;
            }
        }
        else{
            let numerin = auxi*tope;
            let redondo = Math.round(numerin);
            while(j < redondo){
                let barra = listaAux[i].barcode;
                let descripcion = listaAux[i].descripcion;
                fila.push({barra, descripcion});
                j++;
            }
        }
        auxi = auxi - 1 ;
        aux.push(fila);
        j = 0;
        i++;
        }
        */
    setShow(true);
    setListaItems(aux);
  };

  const cerrarModal = () => {
    setShow(false);
  };

  const limpiarCodigoBarras = () => {
    setShowPreguntaEliminarTodo(true);
  };

  const cerrarModalPregunta = () => {
    setShowPregunta(false);
  };

  const cerrarModalEliminaEtiqueta = () => {
    let codigo = auxElminar;
    let nuevo = [];
    items.forEach(function (element) {
      if (element.codigo !== codigo) {
        nuevo.push(element);
      }
    });
    localStorage.removeItem("etiquetas");
    localStorage.setItem("etiquetas", JSON.stringify(nuevo));
    if (items != null) setItems(JSON.parse(localStorage.getItem("etiquetas")));

    setShowPregunta(false);
    toast.success("Etiqueta Eliminada Correctamente", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const cerrarModalPreguntaEliminarTodo = () => {
    setShowPreguntaEliminarTodo(false);
  };

  const cerrarModalPreguntaEliminarConCero = () => {
    setShowPreguntaEliminarConCero(false);
  };

  const cerrarModalEliminaEtiquetaEliminarTodo = () => {
    setItems([]);
    localStorage.removeItem("etiquetas");
    toast.success(<div>Lista de etiquetas borrada correctamente</div>, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      whiteSpace: "pre-line",
    });
    setShowPreguntaEliminarTodo(false);
  };

  const cerrarModalEliminaEtiquetaEliminarConCero = () => {
    let listaAux = [];
    items.forEach(function (elemento) {
      if (elemento.codigo === auxBorrar) {
      } else {
        listaAux.push(elemento);
      }
    });
    localStorage.removeItem("etiquetas");
    localStorage.setItem("etiquetas", JSON.stringify(listaAux));
    if (listaAux != null)
      setItems(JSON.parse(localStorage.getItem("etiquetas")));
    toast.success(<div>Etiqueta borrada correctamente</div>, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      whiteSpace: "pre-line",
    });
    setShowPreguntaEliminarConCero(false);
  };

  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <Col>
          <Row className="d-flex justify-content-center">
            <Button variant="info" onClick={() => imprimirCodigoBarras()}>
              Imprimir
            </Button>
            <Button variant="danger" onClick={() => limpiarCodigoBarras()}>
              Limpiar
            </Button>
          </Row>
          <MUIDataTable
            ref={containerRef}
            title={"Lista de Etiquetas"}
            data={items}
            columns={columns}
            options={options}
            id="tabla"
          ></MUIDataTable>
        </Col>
      </Row>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{tituloModal}</Modal.Title>
          <Button variant="outline-danger" onClick={() => cerrarModal()}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <PDFViewer width="1000" height="600" className="app">
              <CodigosDeBarra items={listaItems} />
            </PDFViewer>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={() => cerrarModal()}>
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPregunta}
        fullscreen={true}
        onHide={() => setShowPregunta(false)}
      >
        <Modal.Header>
          <Modal.Title>Borrar Etiqueta</Modal.Title>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalPregunta()}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>Desea eliminar al etiqueta?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalEliminaEtiqueta()}
          >
            Eliminar Etiqueta
          </Button>
          <Button
            variant="outline-success"
            onClick={() => cerrarModalPregunta()}
          >
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPreguntaEliminarTodo}
        fullscreen={true}
        onHide={() => setShowPreguntaEliminarTodo(false)}
      >
        <Modal.Header>
          <Modal.Title>Borrar Etiquetas</Modal.Title>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalPreguntaEliminarTodo()}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>Desea eliminar todas las etiquetas?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalEliminaEtiquetaEliminarTodo()}
          >
            Eliminar Etiquetas
          </Button>
          <Button
            variant="outline-success"
            onClick={() => cerrarModalPreguntaEliminarTodo()}
          >
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPreguntaEliminarConCero}
        fullscreen={true}
        onHide={() => setShowPreguntaEliminarConCero(false)}
      >
        <Modal.Header>
          <Modal.Title>Borrar Etiqueta</Modal.Title>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalPreguntaEliminarConCero()}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>Desea eliminar todas la etiqueta?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalEliminaEtiquetaEliminarConCero()}
          >
            Eliminar Etiquetas
          </Button>
          <Button
            variant="outline-success"
            onClick={() => cerrarModalPreguntaEliminarConCero()}
          >
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default ImprimirEtiquetas;
