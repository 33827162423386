import axios from "axios";
import authHeader from "./auth-header";
require('dotenv').config();

const API_URL =  process.env.REACT_APP_URL +"/api/movimientos/search/";//http://localhost:8080/api/cli/";

const getMovimientosPP = async (id) => {
    const resultado = await axios.get(API_URL + "pp/" + id, { headers: authHeader() });
    return resultado;
};
const getMovimientosFacturas = async (id) => {
    const resultado = await axios.get(API_URL + "factura/" + id, { headers: authHeader() });
    return resultado;
};

const getMovimientosDevoluciones = async (id) => {
    const resultado = await axios.get(API_URL + "devoluciones/" + id, { headers: authHeader() });
    return resultado;
};

const getEnviarMailPP = async (id, email) => {
    const resultado = await axios.post(process.env.REACT_APP_URL + "/api/movimientos/pdf/pp/" + id ,{email}, {headers: authHeader() });
    return resultado;
};

const getEnviarMailFF = async (id, email) => {
    const resultado = await axios.post(process.env.REACT_APP_URL + "/api/movimientos/pdf/ff/" + id ,{email}, {headers: authHeader() });
    return resultado;
};

const MovimientosService = {
    getMovimientosPP,
    getMovimientosFacturas,
    getMovimientosDevoluciones,
    getEnviarMailPP,
    getEnviarMailFF
};

export default MovimientosService;