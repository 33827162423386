import axios from "axios";
import authHeader from "./auth-header";

require('dotenv').config();

const API_URL = process.env.REACT_APP_URL+"/api/emp/";

const getAllEmpleados = () => {
  return axios.get(API_URL , { headers: authHeader() });
};

const EmpeladoService = {
    getAllEmpleados
};

export default EmpeladoService;
