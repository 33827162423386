import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: JSON.parse(localStorage.getItem("user")),
  },
  reducers: {
    update: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      let user = JSON.parse(localStorage.getItem("user"));
      const nf = new Intl.NumberFormat("es-AR", {
        style: "decimal",
        currency: "ARS",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      let aux = nf.format(user.cuenta_corriente.saldo);
      user.cuenta_corriente.saldo = aux;
      state.value = user;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = userSlice.actions;

export default userSlice.reducer;
