import React, { useState, useEffect, useRef } from "react";

import MovimientosService from "../services/movimientos.service";

import { Container, Row, Col, Modal, Button } from "react-bootstrap/";
import MUIDataTable from "mui-datatables";

import Lottie from "react-lottie";

import * as lapiz from "../assets/reloj.json";
import { toast } from "react-toastify";

const dayjs = require("dayjs");

const CardMovimientos = (rowData) => {
  const [content, setContent] = useState({ rowData: [] });
  const containerRef = useRef(null);
  const [movimientos, setMovimientos] = useState();

  const [loadingData, setLoadingData] = useState(false);

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const [showToast, setShowToast] = useState(false);
  const toggleShowA = () => setShowToast(!showToast);

  useEffect(() => {
    if (rowData.children[0] !== undefined) {
      // PP
      MovimientosService.getMovimientosPP(rowData.children[0]).then(
        (response) => {
          console.log(response.data);
          //response.data.map((articulo) => console.log(articulo));
          setMovimientos(response.data);
          setLoadingData(true);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          //setContent(_content);
          setLoadingData(true);
        }
      );
    }
    if (rowData.children[1] !== undefined) {
      // FF
      MovimientosService.getMovimientosFacturas(rowData.children[1]).then(
        (response) => {
          console.log(response.data);
          //response.data.map((articulo) => console.log(articulo));
          setMovimientos(response.data);
          setLoadingData(true);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          //setContent(_content);
          setLoadingData(true);
        }
      );
    }
    if (rowData.children[2] !== undefined) {
      // NC
      MovimientosService.getMovimientosDevoluciones(rowData.children[2]).then(
        (response) => {
          console.log(response.data);
          //response.data.map((articulo) => console.log(articulo));
          setMovimientos(response.data);
          setLoadingData(true);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          //setContent(_content);
          setLoadingData(true);
        }
      );
    }
  }, [rowData.children]);

  const columns = [
    {
      name: "idMovimientos",
      label: "id Mov",
      options: {
        display: "excluded",
      },
    },
    {
      name: "articulo.articulos_id",
      label: "ID Art",
      options: {
        display: "excluded",
      },
    },
    { name: "articulo.codigo", label: "Codigo" },
    { name: "articulo.descri", label: "desc" },
    { name: "Cantidad", label: "Cantidad" },
    {
      name: "Precio",
      label: "Precio",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return nf.format(value);
        },
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Artículo",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
                      if(expandedRows.data.length>1){
                        let original = expandedRows.data;
                        let aux = expandedRows.data.slice(dataIndex,-1);
                        expandedRows.data = aux;
                        setMostradas(aux);
                        return true;
                      }
                      else{
                        
                      }
                      //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
                      return true;
                      */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData) => {
      console.log(rowData);
    },
    /*
                    renderExpandableRow: (rowData, rowMeta) => {
                      if(!isTabletOrMobile){
                        const colSpan = rowData.length + 1;
                        return (
                          <TableRow>
                            <TableCell colSpan={colSpan} >
                              <Card>{rowData}</Card>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    },
                    */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };
  const lapízOptions = {
    loop: true,
    autoplay: true,
    animationData: lapiz.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const enviarEmail = (e) => {
    console.log(email);
    setLoadingData(false);
    if (error !== "Email inválido") {
      console.log("Válido");
      if (rowData.children[0] !== undefined) {
        // PP
        MovimientosService.getEnviarMailPP(rowData.children[0], email).then(
          (response) => {
            console.log(response.data);
            toast.success(<div>Email enviado correctamente</div>, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              whiteSpace: "pre-line",
            });
            setEmail("");
            setLoadingData(true);
            //response.data.map((articulo) => console.log(articulo));
            //setMovimientos(response.data);
            //setLoadingData(true);
          },
          (error) => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            toast.error(
              <div>
                Error al enviar el email intente nuevamente. De persistir el
                error contáctese con soporte
              </div>,
              {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                whiteSpace: "pre-line",
              }
            );
            //setContent(_content);
            setLoadingData(true);
          }
        );
      }
      if (rowData.children[1] !== undefined) {
        // FF
        MovimientosService.getEnviarMailFF(rowData.children[1], email).then(
          (response) => {
            console.log(response.data);
            toast.success(<div>Email enviado correctamente</div>, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              whiteSpace: "pre-line",
            });
            setEmail("");
            setLoadingData(true);
            //response.data.map((articulo) => console.log(articulo));
            //setMovimientos(response.data);
            //setLoadingData(true);
          },
          (error) => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            toast.error(
              <div>
                Error al enviar el email intente nuevamente. De persistir el
                error contáctese con soporte
              </div>,
              {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                whiteSpace: "pre-line",
              }
            );
            //setContent(_content);
            setLoadingData(true);
          }
        );
      }
      if (rowData.children[2] !== undefined) {
        // NC
      }
    } else {
      console.log("Inválido");
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email inválido");
    } else {
      setError(null);
    }
    if (event.target.value.length === 0) {
      setError(null);
    }
    setEmail(event.target.value);
  };

  return (
    <Container flex>
      <Row className="justify-content-md-center">
        <Col sm={8}>
          <Row className="justify-content-md-center">
            <h5>Enviar PDF por email</h5>
            <div>
              <input
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
              />

              {error && <h2 style={{ color: "red" }}>{error}</h2>}
            </div>
            <Button variant="outline-primary" onClick={() => enviarEmail()}>
              Enviar Email
            </Button>
          </Row>
          <Row className="justify-content-md-center">
            <h5>Lista de artículos en comprobante</h5>
            {!loadingData ? (
              <Lottie options={lapízOptions} height={200} width={200} />
            ) : (
              <div>
                <MUIDataTable
                  ref={containerRef}
                  title={"Lista de artículos en comprobante"}
                  data={movimientos}
                  columns={columns}
                  options={options}
                  id="tabla"
                ></MUIDataTable>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default CardMovimientos;
