import React from "react";
import {
  Page,
  Document,
  Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 14,
  },
  titulo: {
    fontSize: 24,
    fontWeight: "normal",
    padding: 4,
  },
  tituloMediano: {
    fontSize: 18,
    fontWeight: "normal",
    padding: 4,
  },
  dato: {
    fontSize: 24,
    fontWeight: "bold",
  },
  pagos: {
    fontWeight: "normal",
    paddingTop: 4,
    paddingLeft: 20,
  },
  pagosTitulo: {
    fontSize: 14,
  },
  pagoDestacado: {
    fontSize: 24,
    fontWeight: "bold",
  },
  totalPagos: {
    paddingLeft: 220,
  },
  compras: {
    fontWeight: "normal",
    paddingTop: 4,
    paddingBottom: 4,
  },
});

const ResumenDeCuenta = ({
  nombreCliente,
  monto,
  fechaUltimoPago,
  pagosUltimaFecha,
  totalPagosUltimaFecha,
  comprobantesDesdeUltimoPago,
  totalComprobantesDesdeUltimoPago,
}) => (
  <Document>
    <Page size="A4" style={styles.page} wrap>
      <View>
        <Text style={styles.tituloMediano}>
          Nombre: <Text style={styles.dato}>{nombreCliente}</Text>
        </Text>
        {
          // Si no hay pagos en la ultima fecha no se muestra el titulo
          pagosUltimaFecha.length > 0 && (
            <View>
              <Text style={styles.tituloMediano}>
                Movimientos realizados en la ultima fecha:
              </Text>
              <View style={styles.pagos}>
                {pagosUltimaFecha.map((pago, index) => (
                  <Text key={index} style={styles.pagoDestacado}>
                    {index + 1}
                    {"  "}-{"  "}
                    <Text style={styles.pagoDestacado}>
                      {pago.Tipo === "Tarjeta"
                        ? pago.Tipo.slice(0, 8) + "  "
                        : pago.Tipo.slice(0, 8)}
                      {"    "}Monto: ${pago.Total}
                    </Text>
                  </Text>
                ))}
              </View>
            </View>
          )
        }
        {comprobantesDesdeUltimoPago.length > 0 && (
          <View style={styles.compras}>
            <Text style={styles.tituloMediano}>
              Compras realizadas desde la fecha de ultimo pago:
            </Text>
            <View style={styles.pagos}>
              {comprobantesDesdeUltimoPago.map((comprobante, index) => (
                <Text key={index} style={styles.pagoDestacado}>
                  {dayjs(comprobante.fecha).format("DD/MM/YYYY")}
                  {"    "}
                  <Text style={styles.pagoDestacado}>
                    Monto: ${comprobante.importe}
                  </Text>
                </Text>
              ))}
            </View>
          </View>
        )}
        <Text style={styles.tituloMediano}>
          Fecha ultimo pago: <Text style={styles.dato}>{fechaUltimoPago}</Text>
        </Text>
        <Text style={styles.tituloMediano}>
          Saldo: <Text style={styles.dato}>${monto}</Text>
        </Text>
      </View>
    </Page>
  </Document>
);

export default ResumenDeCuenta;
