import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import { Container, ListGroup, Row, Col } from "react-bootstrap/";
import ChartistGraph from "react-chartist";

import ControlFotos from "./ControlFotos";
import CustomTabs from "./customCard/customTabs";
import Tasks from "./customCard/Task";

import ArrowUpward from "@mui/icons-material/ArrowUpward";
import AccessTime from "@mui/icons-material/AccessTime";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import FilterFrames from "@mui/icons-material/FilterFrames";
import Cloud from "@mui/icons-material/Cloud";

import Card from "./customCard/Card.js";
import CardHeader from "./customCard/CardHeader.js";
import CardBody from "./customCard/CardBody.js";
import CardFooter from "./customCard/CardFooter.js";

import { makeStyles } from "@mui/styles";

import styles from "./customCard/dashboardStyle.js";

import { useNavigate } from "react-router-dom";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "./customCard/charts";

const useStyles = makeStyles(styles);

const BoardAdmin = () => {
  const [content, setContent] = useState("");
  const [responseCode, setResponseCode] = useState("");

  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    UserService.getAdminBoard().then(
      (response) => {
        setContent(response.data);
        setResponseCode(response.status);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  var bugs = [
    'Sign contract for "What are conference organizers afraid of?"',
    "Lines From Great Russian Literature? Or E-mails From My Boss?",
    "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
    "Create 4 Invisible User Experiences you Never Knew About",
  ];
  var website = [
    "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
    'Sign contract for "What are conference organizers afraid of?"',
  ];
  var server = [
    "Lines From Great Russian Literature? Or E-mails From My Boss?",
    "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
    'Sign contract for "What are conference organizers afraid of?"',
  ];

  const permisosClicked = () => {
    navigate("/admin/permisos");
  };

  const usuariosClicked = () => {
    navigate("/admin/usuarios");
  };

  const cobrosClicked = () => {
    navigate("/admin/cobros");
  };

  const fotosArticulosClicked = () => {
    navigate("/admin/fotosarticulos");
  };

  if (responseCode === 200) {
    return (
      <Container flex>
        <h3>Administrador</h3>
        {content !== undefined && content !== "No token provided!" ? (
          <div>
            <Row>
              <Col>
                <ListGroup
                  horizontal={"md"}
                  style={{ backgroundColor: "#F9F2ED", borderStyle: "hidden" }}
                >
                  <ListGroup.Item
                    style={{
                      backgroundColor: "#F9F2ED",
                      borderStyle: "hidden",
                    }}
                  >
                    <ListGroup.Item action onClick={cobrosClicked}>
                      Cobros
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={fotosArticulosClicked}>
                      Fotos Artículos
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={permisosClicked}>
                      Permisos
                    </ListGroup.Item>
                    <ListGroup.Item action onClick={usuariosClicked}>
                      Usuarios
                    </ListGroup.Item>
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{
                      backgroundColor: "#F9F2ED",
                      borderStyle: "hidden",
                    }}
                  >
                    <Card chart>
                      <CardHeader color="success">
                        <ChartistGraph
                          className="ct-chart"
                          data={dailySalesChart.data}
                          type="Line"
                          options={dailySalesChart.options}
                          listener={dailySalesChart.animation}
                        />
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardTitle}>
                          Cantidad de Pedidos
                        </h4>
                        <p className={classes.cardCategory}>
                          <span className={classes.successText}>
                            <ArrowUpward
                              className={classes.upArrowCardCategory}
                            />{" "}
                            55%
                          </span>{" "}
                          increase in today sales.
                        </p>
                      </CardBody>
                      <CardFooter chart>
                        <div className={classes.stats}>
                          <AccessTime /> updated 4 minutes ago
                        </div>
                      </CardFooter>
                    </Card>
                  </ListGroup.Item>
                  <ListGroup.Item
                    style={{
                      backgroundColor: "#F9F2ED",
                      borderStyle: "hidden",
                    }}
                  >
                    <Card chart>
                      <CardHeader color="warning">
                        <ChartistGraph
                          className="ct-chart"
                          data={emailsSubscriptionChart.data}
                          type="Bar"
                          options={emailsSubscriptionChart.options}
                          responsiveOptions={
                            emailsSubscriptionChart.responsiveOptions
                          }
                          listener={emailsSubscriptionChart.animation}
                        />
                      </CardHeader>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Ingresos</h4>
                        <p className={classes.cardCategory}>
                          Last Campaign Performance
                        </p>
                      </CardBody>
                      <CardFooter chart>
                        <div className={classes.stats}>
                          <AccessTime /> campaign sent 2 days ago
                        </div>
                      </CardFooter>
                    </Card>
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup
                  horizontal={"md"}
                  style={{ backgroundColor: "#F9F2ED", borderStyle: "hidden" }}
                >
                  <CustomTabs
                    title="Tasks:"
                    headerColor="primary"
                    tabs={[
                      {
                        tabName: "Cajas Actuales",
                        tabIcon: MonetizationOn,
                        tabContent: (
                          <Tasks
                            checkedIndexes={[0, 3]}
                            tasksIndexes={[0, 1, 2, 3]}
                            tasks={bugs}
                          />
                        ),
                      },
                      {
                        tabName: "Pedidos Web Pendientes",
                        tabIcon: FilterFrames,
                        tabContent: (
                          <Tasks
                            checkedIndexes={[0]}
                            tasksIndexes={[0, 1]}
                            tasks={website}
                          />
                        ),
                      },
                      {
                        tabName: "Server",
                        tabIcon: Cloud,
                        tabContent: (
                          <Tasks
                            checkedIndexes={[1]}
                            tasksIndexes={[0, 1, 2]}
                            tasks={server}
                          />
                        ),
                      },
                    ]}
                  />
                </ListGroup>
              </Col>
            </Row>
          </div>
        ) : null}
      </Container>
    );
  } else {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{content}</h3>
        </header>
      </div>
    );
  }
};
// <ControlFotos></ControlFotos>
export default BoardAdmin;
