import React, { useState, useEffect, useRef } from "react";

import { Button, Modal } from "react-bootstrap";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { useNavigate } from "react-router-dom";

import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

import EventBus from "../common/EventBus";

const ResetPasswordLogeado = () => {
  const navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [content, setContent] = useState("");
  const [valido, setValido] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");

  const [password, setPassword] = useState("");
  const [repeatpassword, setRepeatPassword] = useState("");

  // Modal //
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tituloModal, setTituloModal] = useState("");
  const [bodyModal, setBodyModal] = useState("");
  const [tokenVencido, setTokenVencido] = useState(false);
  // Fin Modal //

  const cierreModal = () => {
    setShow(false);
    if (successful) {
      EventBus.dispatch("logout");
    }
  };

  useEffect(() => {}, []);

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (
      checkBtn.current.context._errors.length === 0 &&
      password === repeatpassword
    ) {
      UserService.resetPasswordLogeado(password).then(
        (response) => {
          setMessage(response.data);
          setSuccessful(true);
          setTituloModal("Exito");
          setBodyModal("Se Cambiado la contraseña correctamente.");
          setShow(true);

          //EventBus.dispatch("logout");
        },
        (error) => {
          const resMessage =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          if (
            error.response.data ===
            "El link de reseteo es invalido o ha expirado"
          ) {
            setTokenVencido(true);
          }
          setMessage();
          setSuccessful(false);

          setTituloModal("Error");
          setBodyModal(resMessage);
          setShow(true);
        }
      );
    }
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeRepeatPassword = (e) => {
    const password = e.target.value;
    setRepeatPassword(password);
  };

  const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
      return (
        <div className="alert alert-danger" role="alert">
          La contraseña debe tener entre 6 y 40 caracteres.
        </div>
      );
    }
  };

  const compare = () => {
    var password = document.getElementById("password").value;
    var repeatpassword = document.getElementById("passwordRepeat").value;
    if (password != repeatpassword) {
      return (
        <div className="alert alert-danger" role="alert">
          Las contraseñas deben ser iguales
        </div>
      );
    }
  };

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Campo requerido!
        </div>
      );
    }
  };

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Cambiar contraseña</h3>
        <Form onSubmit={handleRegister} ref={form}>
          <div className="form-group">
            <div className="form-group">
              <label htmlFor="password">Contraseña</label>
              <Input
                id="password"
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={onChangePassword}
                validations={[required, vpassword, compare]}
              />
              <div className="form-group">
                <label htmlFor="passwordRepeat">Repetir Contraseña</label>
                <Input
                  id="passwordRepeat"
                  type="password"
                  className="form-control"
                  name="passwordRepeat"
                  value={repeatpassword}
                  onChange={onChangeRepeatPassword}
                  validations={[required, vpassword, compare]}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <button className="btn btn-primary btn-block">
              Cambiar Contraseña
            </button>
          </div>
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </header>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>{tituloModal}</Modal.Title>
          <Button variant="outline-danger" onClick={() => setShow(false)}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body>{bodyModal}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cierreModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResetPasswordLogeado;
