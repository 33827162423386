import axios from "axios";
import authHeader from "./auth-header";
require("dotenv").config();

const API_URL = process.env.REACT_APP_URL + "/api/cajas/"; //http://localhost:8080/api/cli/";

const searchCaja = async (idUser) => {
  const resultado = await axios.get(API_URL + "ultima/" + idUser, {
    headers: authHeader(),
  });
  return resultado;
};

const cerrarCaja = async (idUser, Observaciones) => {
  const resultado = await axios.post(
    API_URL + "cierre/" + idUser,
    { Observaciones },
    {
      headers: authHeader(),
    }
  );
  return resultado;
};

const getHistorialCajas = async (idUser) => {
  const resultado = await axios.get(API_URL + "historial/" + idUser, {
    headers: authHeader(),
  });
  return resultado;
};

const getHistorialPagosCaja = async (idCaja) => {
  const resultado = await axios.get(API_URL + "pagos/" + idCaja, {
    headers: authHeader(),
  });
  return resultado;
};

const getHistoricoCajas = async () => {
  const resultado = await axios.get(API_URL + "historico/", {
    headers: authHeader(),
  });
  return resultado;
};

const CajasService = {
  searchCaja,
  cerrarCaja,
  getHistorialCajas,
  getHistorialPagosCaja,
  getHistoricoCajas,
};

export default CajasService;
