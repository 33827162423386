import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 8
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
  });


  const BillTo = ({invoice}) => (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Cliente:</Text>
        <Text>{invoice.rowData[5].RazonSocial}</Text>
        <Text>Número Cliente: {invoice.rowData[5].Codigo}</Text>
        <Text>Dirección: {invoice.rowData[5].Direccion}</Text>
        <Text>Telefono: {invoice.rowData[5].Telefono}</Text>
        <Text>Email: {invoice.rowData[5].Email}</Text>
        {
            (invoice.rowData[6] === 0) ? 
            <Text>Estado del Pedido: En Espera</Text>
               :
              (invoice.rowData[6] === -1) ? 
              <Text>Estado del Pedido: Incompleto</Text>
             :
              (invoice.rowData[6] === 1) ?
              <Text>Estado del Pedido: Finalizado</Text>
              :
              (invoice.rowData[6] === -2) ? 
              <Text>Estado del Pedido: Anulado</Text>
              :    
             <Text>Estado del Pedido: {invoice.rowData[6]}</Text>
        }

    </View>
  );
  
  export default BillTo