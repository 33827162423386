import axios from "axios";
import authHeader from "./auth-header";

require('dotenv').config();

const API_URL = process.env.REACT_APP_URL+"/api/test/"; //"http://localhost:8080/api/test/";

const addPedido = (pedido) => {
  let user = JSON.parse(localStorage.getItem('user'));
  return axios.post(process.env.REACT_APP_URL + "/api/pedido/" + user.id,{pedido},{ headers: authHeader() });
};

const getPedidos = async () => {
  let user = JSON.parse(localStorage.getItem('user'));
  const resultado = await axios.get(process.env.REACT_APP_URL  + "/api/pedido/search/" +  user.clienteIdCliente, { headers: authHeader() });
  return resultado;
}
const getMovimientoPedido = async (idPedido) => {
  const resultado = await axios.get(process.env.REACT_APP_URL  + "/api/pedidodetalle/search/" +  idPedido, { headers: authHeader() });
  return resultado;
}

const anularPedidos = async (idPedido) => {
  let user = JSON.parse(localStorage.getItem('user'));
  const resultado = await axios.delete(process.env.REACT_APP_URL  + "/api/pedido/" +  idPedido, { headers: authHeader() });
  return resultado;
}

const PedidoService = {
    addPedido,
    getPedidos,
    getMovimientoPedido,
    anularPedidos
};

export default PedidoService;
