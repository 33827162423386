import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import Articulos from "./Articulo";
import CCService from "../services/cc.service";
import { Container, Row, Col } from "react-bootstrap/";
import { useSelector, useDispatch } from "react-redux";
import { update } from "../redux/userSlice";

const BoardUser = () => {
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    /* Inicio Actualizar Saldo Cliente */
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.cuenta_corriente != null) {
      CCService.getSaldo(user.cuenta_corriente.clienteIdCliente).then(
        (response) => {
          user.cuenta_corriente.saldo = response.data.saldo;
          localStorage.setItem("user", JSON.stringify(user));
          dispatch(update());
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setContent(_content);
          //setLoadingData(true);
        }
      );
    }
    /* Fin Actualizar Saldo Cliente */
    UserService.getUserBoard().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return (
    <Container>
      <h3>Lista de Artículos</h3>
      {content !== undefined && content !== "No token provided!" ? (
        <Articulos>{0}</Articulos>
      ) : (
        console.log("")
      )}
    </Container>
  );
};

export default BoardUser;
