import React from 'react';
import { Page, Document, Image, StyleSheet,Text, View } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../../../src/assets/logo.png'
import InvoiceTableHeader from './InvoiceTableHeader'
import logoHeader from '../../../src/assets/Header.png'

const styles = StyleSheet.create({
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
      },
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
    },
    logo2: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    x:{
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: 40,
    },
    texto:{
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: 8,
    },
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#ff8728',
    },
    informacion:{
        paddingTop: '5px',
        borderColor: 'red',
        borderWidth: '2px',
        textAlign: 'center',
    },
    separador:{
        paddingTop: '5px'
    },
    observacion:{
        paddingTop: '5px',
        paddingLeft: '5px',
        borderColor: '#ff8728',
        borderWidth: '2px',
        textAlign: 'justify',
    },
  });
  
  const Invoice = ({invoice,filas}) => (
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <View>
                            <Text style={styles.x}>X</Text>
                            <Text style={styles.texto}>Documento No Válido Como Factura</Text>
                    </View>
                    <View style={{flexDirection: 'row',   justifyContent: 'center'}}>
                        <InvoiceTitle title='Pedido'/>
                        <Image style={styles.logo} src={logo} />
                    </View>
                    <InvoiceNo invoice={invoice}/>
                    <BillTo invoice={invoice}/>
                    
                    <InvoiceItemsTable invoice={filas} />
                    {
                        invoice.rowData[3] !== "" ? 
                            <View style={styles.separador}>
                                <Text style={styles.observacion}>Observación General: {invoice.rowData[3]}</Text>
                            </View>
                         :
                            console.log("")
                    }
                   
                    <View style={styles.separador}>
                        <Text style={styles.informacion}>Información: Los precios están sujetos a cambios sin previo aviso.</Text>
                    </View>
                </Page>
            </Document>
        );
  
  export default Invoice