import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const dayjs = require('dayjs');

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 46,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 60
    }
    
  });
                
  const InvoiceNo = ({invoice}) => (
        <Fragment>
            <View style={styles.invoiceNoContainer}>
                <Text style={styles.label}>Pedido:</Text>
                <Text style={styles.invoiceDate}>{"N°"+ invoice.rowData[1].toString().replace("N°","").padStart(8,'0')}</Text>
            </View >
            <View style={styles.invoiceDateContainer}>
                <Text style={styles.label}>Fecha: </Text>
                {
                    (dayjs(invoice.rowData[0]).isValid()) ? <Text >{dayjs(invoice.rowData[0]).format('DD/MM/YYYY H:m:ss')}</Text>
                    :
                    <Text >{invoice.rowData[0]}</Text>
                }
            </View >
        </Fragment>
  );
  // <Text style={styles.invoiceDate}>{"0009-"+ invoice.rowData[1].toString().padStart(8,'0')}</Text> Lo dejo acá por las dudas
  export default InvoiceNo