import React, { useState, useEffect, useRef} from "react";

import { Button, Modal} from 'react-bootstrap';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { useNavigate } from 'react-router-dom';

import { isEmail } from "validator";

import AuthService from "../services/auth.service";

const ForgotPassword = () => {
  
  const navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();
  
  const [content, setContent] = useState("");
  const [email, setEmail] = useState(""); 
  const [message, setMessage] = useState("");
  const [successful, setSuccessful] = useState(false);

   // Modal //
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [tituloModal, setTituloModal] = useState("");
   const [bodyModal, setBodyModal] = useState("");
   // Fin Modal //

  useEffect(() => {}, []);

  const cierreModal = (() => {
    setShow(false);
    if(successful){
      navigate('/');
    }
  })
  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Campo requerido!
        </div>
      );
    }
  };
  const validEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Este no es un email valido.
        </div>
      );
    }
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };


  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
        
      AuthService.forgotpassword(email).then(
        (response) => {
          setMessage(response.data);
          setSuccessful(true);
          setTituloModal("Exito");
          setBodyModal("Se ha enviado el mail de recuperación de contraseña correctamente");
          setShow(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data) ||
            error.message ||
            error.toString();

            setMessage();
            setSuccessful(false);

            setTituloModal("Error");
            setBodyModal(resMessage);
            setShow(true);
        }
      );
    }
  };

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Cambiar Contraseña</h3>
        
        <Form onSubmit={handleRegister} ref={form}>
        <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block">Enviar Email</button>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        
        <Modal show={show} onHide={handleClose} centered>
        <Modal.Header >
          <Modal.Title>{tituloModal}</Modal.Title>
          <Button variant="outline-danger" onClick={() => setShow(false)}>X</Button>
        </Modal.Header>
        <Modal.Body>{bodyModal}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cierreModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      </header>
      
    </div>
  );
};

export default ForgotPassword;
