import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { CartProvider } from "react-use-cart";

import store from "./store";
import { Provider } from "react-redux";

ReactDOM.render(
  <BrowserRouter>
    <CartProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </CartProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
//serviceWorker.register();
