import React, { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import ClienteService from "../services/cliente.service";
import Cliente from "./Cliente";
import { Button, Form, Modal } from "react-bootstrap/";
import { render } from "@testing-library/react";
import ResetPasswordLogeado from "./ResetPasswordLogeado";
import CCService from "../services/cc.service";
import { useSelector, useDispatch } from "react-redux";
import { update } from "../redux/userSlice";
const Profile = () => {
  const [content, setContent] = useState();
  const currentUser = AuthService.getCurrentUser();
  const [nuevaGanancia, setNuevaGanancia] = useState(0);

  // Modal //
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tituloModal, setTituloModal] = useState("");
  const [bodyModal, setBodyModal] = useState("");
  // Fin Modal //
  const dispatch = useDispatch();
  useEffect(() => {
    /* Inicio Actualizar Saldo Cliente */
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.cuenta_corriente != null) {
      CCService.getSaldo(user.cuenta_corriente.clienteIdCliente).then(
        (response) => {
          user.cuenta_corriente.saldo = response.data.saldo;
          localStorage.setItem("user", JSON.stringify(user));
          dispatch(update());
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setContent(_content);
          //setLoadingData(true);
        }
      );
    }
    /* Fin Actualizar Saldo Cliente */
    ClienteService.getClienteByID(currentUser.clienteIdCliente).then(
      (response) => {
        console.log(response.data);
        //response.data.map((articulo) => console.log(articulo));
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Datos Cuenta</h3>
        <p>
          <strong>Usuario:</strong> {currentUser.username}
        </p>
        <p>
          <strong>Email:</strong> {currentUser.email}
        </p>
        <p>
          <strong>% de Ganacia:</strong> {currentUser.ganancia}
        </p>
        <p>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Ingrese % de Ganancia</Form.Label>
              <Form.Control
                type="number"
                placeholder="%"
                id="nuevaGancia"
                onChange={(e) => {
                  if (
                    parseFloat(e.target.value) < 0 ||
                    isNaN(parseFloat(e.target.value))
                  ) {
                    setNuevaGanancia(0);
                  } else {
                    setNuevaGanancia(parseFloat(e.target.value));
                  }
                }}
              />
              <Form.Text className="text-muted">
                Cambiar Porcentaje de ganancia.
              </Form.Text>
            </Form.Group>
            <Button
              variant="primary"
              onClick={(e) => {
                if (nuevaGanancia != 0) {
                  console.log("Nueva Ganancia: " + nuevaGanancia);
                  ClienteService.putGanancia(
                    currentUser.id,
                    nuevaGanancia
                  ).then(
                    (response) => {
                      //console.log(response.data);
                      const user = JSON.parse(localStorage.getItem("user"));
                      user.ganancia = nuevaGanancia;
                      localStorage.setItem("user", JSON.stringify(user)); // Acá cambio los datos del usuario local
                      setTituloModal("Exito");
                      setBodyModal(
                        "Se ha cambiado el % de ganancia correctamente"
                      );
                      setShow(true);
                    },
                    (error) => {
                      const _content =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                      //setBodyModal(_content);
                      setTituloModal("Error");
                      setBodyModal(
                        "Ha ocurrido un error intente nuevamente, de persistir contáctese con soporte"
                      );
                      setShow(true);
                    }
                  );
                } else {
                  setTituloModal("Error");
                  setBodyModal("Ingrese un valor correcto como % de ganancia");
                  setShow(true);
                }
              }}
            >
              Cambiar Ganancia
            </Button>
          </Form>
        </p>
      </header>
      <header className="jumbotron">
        <h3>Vinculada al cliente:</h3>
        {content !== undefined ? <Cliente>{content}</Cliente> : console.log("")}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header>
            <Modal.Title>{tituloModal}</Modal.Title>
            <Button variant="outline-danger" onClick={() => setShow(false)}>
              X
            </Button>
          </Modal.Header>
          <Modal.Body>{bodyModal}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </header>
      <header className="jumbotron">
        <ResetPasswordLogeado></ResetPasswordLogeado>
      </header>
    </div>
  );
};

export default Profile;
