import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import { Container, Row, Col, Image, Card } from "react-bootstrap/";

import banner from "../assets/banner2.png";
import wsp from "../assets/wsp.png";

const Home = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  /*
  // Original 
  return (
    <Container fluid >
     
      <Row className="d-flex justify-content-center"  >
      <Image src={banner} fluid/>
      </Row>
      <hr></hr>
      <Row >
        <Col >
          <Row className="d-flex justify-content-center"><h2 style={{color: "#3AB0FF"}}>Nuestra Misión</h2></Row>
          <p>Somos una empresa que demuestra una constante preocupación por sus clientes, ofreciendo junto con nuestro personal, una atención profesional, eficaz, cordial y con una gran experiencia en el mercado, brindando así una alta seguridad y satisfacción a nuestra clientela.</p>
        </Col>
      </Row>
      <hr></hr>
      <Row style={{backgroundColor: "#F9F2ED"}}>
        <Col>
          <Row className="d-flex justify-content-center"><h2 style={{color: "#3AB0FF"}}>Nuestra Visión</h2></Row>
          <p>Ser los líderes en el rubro mayorista de Librería, Mercería y Juguetería siendo reconocidos como la empresa de mayor trayectoria y confiabilidad del mercado. Y así, con una constante preocupación por nuestros clientes, poderles brindar el apoyo, asesoramiento y respaldo que estos nos soliciten .</p>
        </Col>
      </Row>
      <hr></hr>
      <Row style={{backgroundColor: "#F9F2ED"}}>
        <Col>
            <Row className="d-flex justify-content-center"><h2 style={{color: "#3AB0FF"}}>Nuestros Valores</h2></Row>
            <li>Humildad: Reconociendo nuestros defectos  para mejorarlos y al mismo tiempo conocemos cuáles son nuestras virtudes para llevarlas a su máxima expresión.</li>
            <li>Pasión: Comprometiéndonos con nuestra fuerzas, el corazón, el alma y la mente en todo lo que emprendamos.</li>
            <li>Integridad: Siendo muy transparentes con nuestro personal, proveedores y clientes.</li>
            <li>Colaboración: Incentivando el trabajo en equipo para poder crear un esfuerzo sinérgico.</li>
            <li>Compromiso: Brindando nuestros servicios y ofreciendo nuestra ayuda tanto al momento de generar la venta como en el post-venta.</li>         
            <li>Coherencia: Entre nuestros valores y nuestros objetivos. </li>
            <li>Confianza: Evitando defraudar a nuestros clientes, Proveedores y personal, considerando que la base de una relación en el tiempo es la confianza mutua.</li>
            <li>Calidad: Buscando en todo momento la excelencia.</li>
        </Col>
      </Row>
      <hr></hr>
      <Col style={{backgroundColor: "#7a181b", color :"white"}}>
        <h4>Nuestra atención y venta Mayorista es 11:30hs a 15:30 hs de Lunes a Viernes.
        NO CONTAMOS CON VENTA MINORISTA.
        Para más información o consultas, pónganse en contacto con nosotros. </h4>
      </Col>
      <hr></hr>
      <Row>
        <Col className="embed-responsive embed-responsive-16by9">
        <iframe  className="embed-responsive-item" jsname="L5Fo6c" class="YMEQtf" sandbox="allow-scripts allow-popups allow-forms allow-same-origin allow-popups-to-escape-sandbox allow-downloads allow-modals" frameborder="0" aria-label="Map, Karam José e Hijos Mercería y Librería" src="https://maps-api-ssl.google.com/maps?hl=es-419&amp;ll=-31.530611,-68.521914&amp;output=embed&amp;q=Av.+Rioja+Norte+360,+J5400+EMQ,+San+Juan,+Argentina+(Karam+Jos%C3%A9+e+Hijos+Mercer%C3%ADa+y+Librer%C3%ADa)&amp;z=16" allowfullscreen=""></iframe>
        </Col>
        
      </Row>
      <Row>
      <Col className="d-flex justify-content-center">
        <p>Nos encontramos en Av. Rioja, 360 (Norte) entre Calles Maipú y Pedro Echagüe.</p>
      </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col  xs={12} sm={4} md={4}>
        <a href="https://wa.me/5492644605714?text=Hola, quisiera hacer una consulta." target="_blank">
          <Card className="bg-dark text-white">
            <Card.Img variant="top" src={wsp}/>
            <Card.Body>
              <Card.Title>
              <p>Contáctese con nosotros por WhatsApp José Karam E Hijos</p>
              </Card.Title>
            </Card.Body>
          </Card>
          </a>
        </Col>
      </Row>
       </Container>
  );
  */

  // Testing
  return <Container fluid></Container>;
};

export default Home;
