import React, { useState, useEffect } from "react";


import Camera from "./cameraHandler2/index";

const PruebasCamara = () => {
  const [content, setContent] = useState();

    
  return (
    <div className="container">
      <Camera/>
    </div>
  );
};

export default PruebasCamara;
