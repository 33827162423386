import React, { useState, useEffect, useRef } from "react";
import UsuariosService from "../services/user.service";
import ClienteService from "../services/cliente.service";
import EmpeladoService from "../services/empleados.service";
import EventBus from "../common/EventBus";
import { ListGroup, Modal } from "react-bootstrap/";
import CustomTabs from "./customCard/customTabs";
import MUIDataTable from "mui-datatables";
import { Button, Row, Col } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";

import BadgeIcon from "@mui/icons-material/Badge";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LockResetIcon from "@mui/icons-material/LockReset";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

const Usuarios = () => {
  const [content, setContent] = useState("");
  const [responseCode, setResponseCode] = useState("");
  const [clientes, setClientes] = useState([]);
  const [libres, setLibres] = useState([]);
  const [empleados, setEmpleados] = useState([]);
  const [todos, setTodos] = useState([]);
  const [eliminados, setEliminados] = useState([]);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [buscarClientes, setBuscarClientes] = useState([]);
  const [elegidoAsignarNuevoCliente, setElegidoAsignarNuevoCliente] =
    useState("");
  const [userElegidoAsignar, setUserElegidoAsignar] = useState("");
  const [buscarEmpleados, setBuscarEmpleados] = useState([]);
  const [elegidoAsignarNuevoEmpleado, setElegidoAsignarNuevoEmpleado] =
    useState("");
  const [userElegidoDelete, setUserElegidoDelete] = useState("");
  const [userElegidoResetPassword, setUserElegidoResetPassword] = useState("");
  const [userElegidoDesvincularCliente, setUserElegidoDesvincularCliente] =
    useState("");
  const [userElegidoDesvincularEmpleado, setUserElegidoDesvincularEmpleado] =
    useState("");
  const [userElegidoRescatar, setUserElegidoRescatar] = useState("");

  // Modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [tituloModal, setTituloModal] = useState("");
  const [modo, setModo] = useState("");
  // Fin Modal

  // Modal Pregunta//
  const [tituloModalPregunta, setTituloModalPregunta] = useState("Atención");
  const [fullscreenPregunta, setFullscreenPregunta] = useState(true);
  const [showPregunta, setShowPregunta] = useState(false);
  // Fin Modal Pregunta//

  // Modal Pregunta Empleado//
  const [tituloModalPreguntaEmpleado, setTituloModalPreguntaEmpleado] =
    useState("Atención");
  const [fullscreenPreguntaEmpleado, setFullscreenPreguntaEmpleado] =
    useState(true);
  const [showPreguntaEmpleado, setShowPreguntaEmpleado] = useState(false);
  // Fin Modal Pregunta Empleado//

  // Modal Pregunta Delete//
  const [tituloModalPreguntaDelete, setTituloModalPreguntaDelete] =
    useState("Atención");
  const [fullscreenPreguntaDelete, setFullscreenPreguntaDelete] =
    useState(true);
  const [showPreguntaDelete, setShowPreguntaDelete] = useState(false);
  // Fin Modal Pregunta Delete//

  // Modal Pregunta Reset Password//
  const [
    tituloModalPreguntaResetpassword,
    setTituloModalPreguntaResetPassword,
  ] = useState("Atención");
  const [fullscreenPreguntaResetPassword, setFullscreenPreguntaResetPassword] =
    useState(true);
  const [showPreguntaResetPassword, setShowPreguntaResetPassword] =
    useState(false);
  // Fin Modal Pregunta Reset Password//

  // Modal Pregunta Desvincular Cliente//
  const [
    tituloModalPreguntaDesvincularCliente,
    setTituloModalPreguntaDesvincularCliente,
  ] = useState("Atención");
  const [
    fullscreenPreguntaDesvincularCliente,
    setFullscreenPreguntaDesvincularCliente,
  ] = useState(true);
  const [showPreguntaDesvincularCliente, setShowPreguntaDesvincularCliente] =
    useState(false);
  // Fin Modal Pregunta Desvincular Cliente//

  // Modal Pregunta Desvincular Empleado//
  const [
    tituloModalPreguntaDesvincularEmpleado,
    setTituloModalPreguntaDesvincularEmpleado,
  ] = useState("Atención");
  const [
    fullscreenPreguntaDesvincularEmpleado,
    setFullscreenPreguntaDesvincularEmpleado,
  ] = useState(true);
  const [showPreguntaDesvincularEmpleado, setShowPreguntaDesvincularEmpleado] =
    useState(false);
  // Fin Modal Pregunta Desvincular Empleado//

  // Modal Pregunta Rescatar Eliminado//
  const [
    tituloModalPreguntaRescatarEliminado,
    setTituloModalPreguntaRescatarEliminado,
  ] = useState("Atención");
  const [
    fullscreenPreguntaRescatarEliminado,
    setFullscreenPreguntaRescatarEliminado,
  ] = useState(true);
  const [showPreguntaRescatarEliminado, setShowPreguntaRescatarEliminado] =
    useState(false);
  // Fin Modal Pregunta Rescatar Eliminado//

  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    UsuariosService.getAllUsers().then(
      (response) => {
        UsuariosService.getEliminados().then(
          (eliminados) => {
            setEliminados(eliminados.data);
            setClientes(response.data.clientes);
            setLibres(response.data.libres);
            setEmpleados(response.data.empleados);
            setTodos(response.data.todos);
            setResponseCode(response.status);
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setContent(_content);

            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
            }
          }
        );
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columnsLibres = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "username",
      label: "Nombre de Usuario",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "Opciones",
      label: "Opciones",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{}}>
              <Button
                onClick={() => {
                  ClienteService.getClientesAll().then(
                    (response) => {
                      setBuscarClientes(response.data);
                      setTituloModal("Agregar cliente al usuario");
                      setShow(true);
                      setModo("cliente");
                      setUserElegidoAsignar(tableMeta.rowData[0]);
                    },
                    (error) => {
                      const _content =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();

                      setContent(_content);

                      if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                      }
                    }
                  );
                }}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <IconButton
                  aria-label="Edit"
                  style={{
                    backgroundColor: "transparent",
                    color: "#4caf50",
                    boxShadow: "none",
                  }}
                >
                  <PersonAddAlt1Icon />
                </IconButton>
              </Button>
              <Button
                onClick={() => {
                  setTituloModal("Agregar Empleado al usuario");
                  setShow(true);
                  setModo("empleado");
                  EmpeladoService.getAllEmpleados().then(
                    (response) => {
                      setBuscarEmpleados(response.data);
                      setTituloModal("Agregar empleado al usuario");
                      setShow(true);
                      setModo("empleado");
                      setUserElegidoAsignar(tableMeta.rowData[0]);
                    },
                    (error) => {
                      const _content =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();

                      setContent(_content);

                      if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                      }
                    }
                  );
                }}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <IconButton
                  aria-label="Edit"
                  style={{
                    backgroundColor: "transparent",
                    color: "#ff9800",
                    boxShadow: "none",
                  }}
                >
                  <EngineeringIcon />
                </IconButton>
              </Button>
              <Button
                onClick={() => {
                  setUserElegidoDelete(tableMeta.rowData[0]);
                  setShowPreguntaDelete(true);
                }}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <IconButton
                  aria-label="Edit"
                  style={{
                    backgroundColor: "transparent",
                    color: "#f44336",
                    boxShadow: "none",
                  }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Button>
            </Row>
          );
        },
      },
    },
  ];

  const columnsClientes = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "username",
      label: "Nombre de Usuario",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "Opciones",
      label: "Opciones",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{}}>
              <Button
                onClick={() => {
                  setUserElegidoResetPassword(tableMeta.rowData[0]);
                  setShowPreguntaResetPassword(true);
                }}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <IconButton
                  aria-label="Reset Password"
                  style={{
                    backgroundColor: "transparent",
                    color: "#4caf50",
                    boxShadow: "none",
                  }}
                >
                  <LockResetIcon />
                </IconButton>
              </Button>
              <Button
                onClick={() => {
                  setUserElegidoDesvincularCliente(tableMeta.rowData[0]);
                  setShowPreguntaDesvincularCliente(true);
                }}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <IconButton
                  aria-label="Desvincular"
                  style={{
                    backgroundColor: "transparent",
                    color: "#f44336",
                    boxShadow: "none",
                  }}
                >
                  <PersonRemoveIcon />
                </IconButton>
              </Button>
            </Row>
          );
        },
      },
    },
  ];

  const columnsEmpleados = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "username",
      label: "Nombre de Usuario",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "Opciones",
      label: "Opciones",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{}}>
              <Button
                onClick={() => {
                  setUserElegidoResetPassword(tableMeta.rowData[0]);
                  setShowPreguntaResetPassword(true);
                }}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <IconButton
                  aria-label="Reset Password"
                  style={{
                    backgroundColor: "transparent",
                    color: "#4caf50",
                    boxShadow: "none",
                  }}
                >
                  <LockResetIcon />
                </IconButton>
              </Button>
              <Button
                onClick={() => {
                  setUserElegidoDesvincularEmpleado(tableMeta.rowData[0]);
                  setShowPreguntaDesvincularEmpleado(true);
                }}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <IconButton
                  aria-label="Desvincular"
                  style={{
                    backgroundColor: "transparent",
                    color: "#f44336",
                    boxShadow: "none",
                  }}
                >
                  <PersonRemoveIcon />
                </IconButton>
              </Button>
            </Row>
          );
        },
      },
    },
  ];

  const columnsBuscarClientes = [
    {
      name: "idCliente",
      label: "idCliente",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Cuit",
      label: "CUIT",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "RazonSocial",
      label: "Razón Social",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "Opciones",
      label: "Opciones",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{}}>
              <Button
                onClick={() => {
                  console.log(tableMeta.rowData[0]);
                  setElegidoAsignarNuevoCliente(tableMeta.rowData[0]);
                  setShowPregunta(true);
                }}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <IconButton
                  aria-label="Edit"
                  style={{
                    backgroundColor: "transparent",
                    color: "#4caf50",
                    boxShadow: "none",
                  }}
                >
                  <PersonAddAlt1Icon />
                </IconButton>
              </Button>
            </Row>
          );
        },
      },
    },
  ];

  const columnsBuscarEmpleados = [
    {
      name: "DNI",
      label: "DNI",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Nombre",
      label: "Nombre",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{ paddingLeft: 30 }}>
              {value + " " + tableMeta.rowData[2]}
            </Row>
          );
        },
      },
    },
    {
      name: "Apellido",
      label: "Apellido",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Opciones",
      label: "Opciones",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{}}>
              <Button
                onClick={() => {
                  console.log(tableMeta.rowData[0]);
                  setElegidoAsignarNuevoEmpleado(tableMeta.rowData[0]);
                  setShowPreguntaEmpleado(true);
                }}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <IconButton
                  aria-label="Edit"
                  style={{
                    backgroundColor: "transparent",
                    color: "#ff9800",
                    boxShadow: "none",
                  }}
                >
                  <EngineeringIcon />
                </IconButton>
              </Button>
            </Row>
          );
        },
      },
    },
  ];

  const columnsEliminados = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "username",
      label: "Nombre de Usuario",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "Opciones",
      label: "Opciones",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{}}>
              <Button
                onClick={() => {
                  setUserElegidoRescatar(tableMeta.rowData[0]);
                  setShowPreguntaRescatarEliminado(true);
                }}
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <IconButton
                  aria-label="Reset Password"
                  style={{
                    backgroundColor: "transparent",
                    color: "#4caf50",
                    boxShadow: "none",
                  }}
                >
                  <PersonAddAltIcon />
                </IconButton>
              </Button>
            </Row>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Usuario",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
          if(expandedRows.data.length>1){
            let original = expandedRows.data;
            let aux = expandedRows.data.slice(dataIndex,-1);
            expandedRows.data = aux;
            setMostradas(aux);
            return true;
          }
          else{
            
          }
          //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
          return true;
          */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData) => {
      console.log(rowData);
    },
    /*
        renderExpandableRow: (rowData, rowMeta) => {
          if(!isTabletOrMobile){
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan} >
                  <Card>{rowData}</Card>
                </TableCell>
              </TableRow>
            );
          }
        },
        */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const optionsBuscarClientes = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Clientes",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
    if(expandedRows.data.length>1){
      let original = expandedRows.data;
      let aux = expandedRows.data.slice(dataIndex,-1);
      expandedRows.data = aux;
      setMostradas(aux);
      return true;
    }
    else{
      
    }
    //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
    return true;
    */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData) => {
      console.log(rowData);
    },
    /*
  renderExpandableRow: (rowData, rowMeta) => {
    if(!isTabletOrMobile){
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan} >
            <Card>{rowData}</Card>
          </TableCell>
        </TableRow>
      );
    }
  },
  */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const cerrarModal = () => {
    setShow(false);
  };

  const cerrarModalPregunta = () => {
    setShowPregunta(false);
  };

  const cerrarModalPreguntaEmpleado = () => {
    setShowPreguntaEmpleado(false);
  };

  const cerrarModalPreguntaDelete = () => {
    setShowPreguntaDelete(false);
  };

  const cerrarModalPreguntaResetPassword = () => {
    setShowPreguntaResetPassword(false);
  };

  const cerrarModalPreguntaDesvincularCliente = () => {
    setShowPreguntaDesvincularCliente(false);
  };

  const cerrarModalPreguntaDesvincularEmpleado = () => {
    setShowPreguntaDesvincularEmpleado(false);
  };

  const cerrarModalPreguntaRescatarEliminado = () => {
    setShowPreguntaRescatarEliminado(false);
  };

  const cerrarModalActivaUsuario = () => {
    UsuariosService.activarUsuarioCliente({
      id: userElegidoAsignar,
      clienteIdCliente: elegidoAsignarNuevoCliente,
    }).then(
      (response) => {
        console.log(response);
        toast.success(<div>Cliente activado correctamente</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        window.location.reload();

        //setShowPregunta(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(
          <div>
            Error al activar el cliente. De persistir el error contáctese con
            soporte
          </div>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          }
        );
        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const cerrarModalActivaEmpleado = () => {
    UsuariosService.activarUsuarioEmpleado({
      id: userElegidoAsignar,
      EmpleadoDNI: elegidoAsignarNuevoEmpleado,
    }).then(
      (response) => {
        console.log(response);
        toast.success(<div>Empleado activado correctamente</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        window.location.reload();

        //setShowPregunta(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(
          <div>
            Error al activar el empleado. De persistir el error contáctese con
            soporte
          </div>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          }
        );
        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const cerrarModalDelete = () => {
    UsuariosService.deleteUsuario(userElegidoDelete).then(
      (response) => {
        console.log(response);
        toast.success(<div>Empleado eliminado correctamente</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        window.location.reload();

        //setShowPregunta(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(
          <div>
            Error al eliminar el empleado. De persistir el error contáctese con
            soporte
          </div>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          }
        );
        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const cerrarModalResetPassword = () => {
    UsuariosService.resetPasswordDefaultUsuario(userElegidoResetPassword).then(
      (response) => {
        console.log(response);
        toast.success(<div>Contraseña reseteada correctamente</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        window.location.reload();

        //setShowPregunta(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(
          <div>
            Error al resetear la contraseña. De persistir el error contáctese
            con soporte
          </div>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          }
        );
        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const cerrarModalDesvincularCliente = () => {
    UsuariosService.desvincularCliente(userElegidoDesvincularCliente).then(
      (response) => {
        console.log(response);
        toast.success(
          <div>Cliente desvinculado correctamente del usuario</div>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          }
        );
        window.location.reload();

        //setShowPregunta(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(
          <div>
            Error al desvincular cliente del usuario. De persistir el error
            contáctese con soporte
          </div>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          }
        );
        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const cerrarModalDesvincularEmpleado = () => {
    UsuariosService.desvincularEmpleado(userElegidoDesvincularEmpleado).then(
      (response) => {
        console.log(response);
        toast.success(
          <div>Empleado desvinculado correctamente del usuario</div>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          }
        );
        window.location.reload();

        //setShowPregunta(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(
          <div>
            Error al desvincular empleado del usuario. De persistir el error
            contáctese con soporte
          </div>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          }
        );
        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const cerrarModalRescatarEliminado = () => {
    UsuariosService.rescatarUsuarioEliminado(userElegidoRescatar).then(
      (response) => {
        console.log(response);
        toast.success(<div>Usuario rescatado correctamente</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        window.location.reload();

        //setShowPregunta(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(
          <div>
            Error al rescatar el usuario. De persistir el error contáctese con
            soporte
          </div>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          }
        );
        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  if (responseCode === 200) {
    return content !== undefined && content !== "No token provided!" ? (
      <div>
        <h1>Usuarios</h1>
        <Modal
          show={showPregunta}
          fullscreen={true}
          onHide={() => setShowPregunta(false)}
        >
          <Modal.Header>
            <Modal.Title>Agregar Cliente</Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalPregunta()}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>Desea agregar el cliente?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalActivaUsuario()}
            >
              Agregar Cliente
            </Button>
            <Button
              variant="outline-success"
              onClick={() => cerrarModalPregunta()}
            >
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showPreguntaEmpleado}
          fullscreen={true}
          onHide={() => setShowPreguntaEmpleado(false)}
        >
          <Modal.Header>
            <Modal.Title>Agregar Empleado</Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalPreguntaEmpleado()}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>Desea agregar al Empleado?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalActivaEmpleado()}
            >
              Agregar Empleado
            </Button>
            <Button
              variant="outline-success"
              onClick={() => cerrarModalPreguntaEmpleado()}
            >
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showPreguntaDelete}
          fullscreen={true}
          onHide={() => setShowPreguntaDelete(false)}
        >
          <Modal.Header>
            <Modal.Title>Eliminar Usuario</Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalPreguntaDelete()}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>Desea eliminar al usuario?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalDelete()}
            >
              Eliminar Usuario
            </Button>
            <Button
              variant="outline-success"
              onClick={() => cerrarModalPreguntaDelete()}
            >
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showPreguntaResetPassword}
          fullscreen={true}
          onHide={() => setShowPreguntaResetPassword(false)}
        >
          <Modal.Header>
            <Modal.Title>Reset Contraseña de Usuario</Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalPreguntaResetPassword()}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>Desea resetear la contraseña al usuario?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalResetPassword()}
            >
              Reset Contraseña
            </Button>
            <Button
              variant="outline-success"
              onClick={() => cerrarModalPreguntaResetPassword()}
            >
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showPreguntaDesvincularCliente}
          fullscreen={true}
          onHide={() => setShowPreguntaDesvincularCliente(false)}
        >
          <Modal.Header>
            <Modal.Title>Desvincular cliente del Usuario</Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalPreguntaDesvincularCliente()}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>Desea desvincular el cliente del usuario?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalDesvincularCliente()}
            >
              Desvincular Cliente
            </Button>
            <Button
              variant="outline-success"
              onClick={() => cerrarModalPreguntaDesvincularCliente()}
            >
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showPreguntaDesvincularEmpleado}
          fullscreen={true}
          onHide={() => setShowPreguntaDesvincularEmpleado(false)}
        >
          <Modal.Header>
            <Modal.Title>Desvincular empleado del Usuario</Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalPreguntaDesvincularEmpleado()}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>Desea desvincular el empleado del usuario?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalDesvincularEmpleado()}
            >
              Desvincular Empelado
            </Button>
            <Button
              variant="outline-success"
              onClick={() => cerrarModalPreguntaDesvincularEmpleado()}
            >
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showPreguntaRescatarEliminado}
          fullscreen={true}
          onHide={() => setShowPreguntaRescatarEliminado(false)}
        >
          <Modal.Header>
            <Modal.Title>Rescatar usuario eliminado</Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalPreguntaRescatarEliminado()}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>Desea rescatar el usuario eliminado?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalRescatarEliminado()}
            >
              Rescatar Usuario
            </Button>
            <Button
              variant="outline-success"
              onClick={() => cerrarModalPreguntaRescatarEliminado()}
            >
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
        >
          <Modal.Header>
            <Modal.Title>{tituloModal}</Modal.Title>
            <Button variant="outline-danger" onClick={() => cerrarModal()}>
              X
            </Button>
          </Modal.Header>
          <Modal.Body>
            <Row className="d-flex justify-content-center">
              {modo === "cliente" ? (
                <div>
                  <MUIDataTable
                    ref={containerRef}
                    title={"Clientes"}
                    data={buscarClientes}
                    columns={columnsBuscarClientes}
                    options={optionsBuscarClientes}
                    id="tablaBuscarClientes"
                  ></MUIDataTable>
                </div>
              ) : modo === "empleado" ? (
                <div>
                  <MUIDataTable
                    ref={containerRef}
                    title={"Empleados"}
                    data={buscarEmpleados}
                    columns={columnsBuscarEmpleados}
                    options={optionsBuscarClientes}
                    id="tablaBuscarEmpleados"
                  ></MUIDataTable>
                </div>
              ) : null}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={() => cerrarModal()}>
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <ListGroup
          horizontal={"md"}
          style={{ backgroundColor: "#F9F2ED", borderStyle: "hidden" }}
        >
          <CustomTabs
            headerColor="primary"
            tabs={[
              {
                tabName: "libres",
                tabIcon: PersonOutlineIcon,
                tabContent: (
                  <div>
                    <ListGroup style={{ borderStyle: "hidden" }}>
                      <Tooltip>
                        <IconButton
                          aria-label="Agregar cliente"
                          style={{
                            backgroundColor: "transparent",
                            color: "#4caf50",
                            boxShadow: "none",
                          }}
                        >
                          <PersonAddAlt1Icon />
                          <p style={{ paddingLeft: 20, marginTop: 10 }}>
                            Agregar cliente
                          </p>
                        </IconButton>
                        <IconButton
                          aria-label="Agregar empleado"
                          style={{
                            backgroundColor: "transparent",
                            color: "#ff9800",
                            boxShadow: "none",
                          }}
                        >
                          <EngineeringIcon />
                          <p style={{ paddingLeft: 20, marginTop: 10 }}>
                            Agregar empleado
                          </p>
                        </IconButton>
                        <IconButton
                          aria-label="Eliminar"
                          style={{
                            backgroundColor: "transparent",
                            color: "#f44336",
                            boxShadow: "none",
                          }}
                        >
                          <RemoveCircleOutlineIcon />
                          <p style={{ paddingLeft: 20, marginTop: 10 }}>
                            Eliminar
                          </p>
                        </IconButton>
                      </Tooltip>
                    </ListGroup>
                    <MUIDataTable
                      ref={containerRef}
                      title={"Usuarios libres"}
                      data={libres}
                      columns={columnsLibres}
                      options={options}
                      id="tabla"
                    ></MUIDataTable>
                  </div>
                ),
              },
              {
                tabName: "clientes",
                tabIcon: PersonIcon,
                tabContent: (
                  <div>
                    <ListGroup style={{ borderStyle: "hidden" }}>
                      <Tooltip>
                        <IconButton
                          aria-label="Reset Password"
                          style={{
                            backgroundColor: "transparent",
                            color: "#4caf50",
                            boxShadow: "none",
                          }}
                        >
                          <LockResetIcon />
                          <p style={{ paddingLeft: 20, marginTop: 10 }}>
                            Resetear contraseña
                          </p>
                        </IconButton>
                        <IconButton
                          aria-label="Desvincular"
                          style={{
                            backgroundColor: "transparent",
                            color: "#f44336",
                            boxShadow: "none",
                          }}
                        >
                          <PersonRemoveIcon />
                          <p style={{ paddingLeft: 20, marginTop: 10 }}>
                            Desvincular
                          </p>
                        </IconButton>
                      </Tooltip>
                    </ListGroup>
                    <MUIDataTable
                      ref={containerRef}
                      title={"Clientes"}
                      data={clientes}
                      columns={columnsClientes}
                      options={options}
                      id="tabla"
                    ></MUIDataTable>
                  </div>
                ),
              },
              {
                tabName: "empleados",
                tabIcon: BadgeIcon,
                tabContent: (
                  <div>
                    <ListGroup style={{ borderStyle: "hidden" }}>
                      <Tooltip>
                        <IconButton
                          aria-label="Reset Password"
                          style={{
                            backgroundColor: "transparent",
                            color: "#4caf50",
                            boxShadow: "none",
                          }}
                        >
                          <LockResetIcon />
                          <p style={{ paddingLeft: 20, marginTop: 10 }}>
                            Resetear contraseña
                          </p>
                        </IconButton>
                        <IconButton
                          aria-label="Desvincular"
                          style={{
                            backgroundColor: "transparent",
                            color: "#f44336",
                            boxShadow: "none",
                          }}
                        >
                          <PersonRemoveIcon />
                          <p style={{ paddingLeft: 20, marginTop: 10 }}>
                            Desvincular
                          </p>
                        </IconButton>
                      </Tooltip>
                    </ListGroup>
                    <MUIDataTable
                      ref={containerRef}
                      title={"Empleados"}
                      data={empleados}
                      columns={columnsEmpleados}
                      options={options}
                      id="tabla"
                    ></MUIDataTable>
                  </div>
                ),
              },
              {
                tabName: "eliminados",
                tabIcon: DeleteIcon,
                tabContent: (
                  <div>
                    <ListGroup style={{ borderStyle: "hidden" }}>
                      <Tooltip>
                        <IconButton
                          aria-label="Rescatar"
                          style={{
                            backgroundColor: "transparent",
                            color: "#4caf50",
                            boxShadow: "none",
                          }}
                        >
                          <PersonAddAltIcon />
                          <p style={{ paddingLeft: 20, marginTop: 10 }}>
                            Rescatar
                          </p>
                        </IconButton>
                      </Tooltip>
                    </ListGroup>
                    <MUIDataTable
                      ref={containerRef}
                      title={"Eliminados"}
                      data={eliminados}
                      columns={columnsEliminados}
                      options={options}
                      id="tablaEliminados"
                    ></MUIDataTable>
                  </div>
                ),
              },
            ]}
          />
        </ListGroup>
      </div>
    ) : null;
  } else {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{content}</h3>
        </header>
      </div>
    );
  }
};

export default Usuarios;
