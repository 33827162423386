import React, { useState, useEffect, useRef } from "react";

import { Container } from "react-bootstrap/";
import { useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Button, Row, Col } from "react-bootstrap";

import EventBus from "../common/EventBus";
import PermisosService from "../services/permisos.service";
import { MDBCheckbox } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const TablaPermisos = () => {
  const [content, setContent] = useState("");
  const [responseCode, setResponseCode] = useState("");
  const { id } = useParams();
  const containerRef = useRef(null);

  const [listaPermisos, setListaPermisos] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    PermisosService.getSearchUserConPermisos(id).then(
      (response) => {
        setListaPermisos(response.data);
        setResponseCode(response.status);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columns = [
    {
      name: "idPermiso",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Denominacion",
      label: "Denominacion",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "Habilitado",
      label: "Habilitado",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{ paddingLeft: 90, paddingBottom: 20 }}>
              <MDBCheckbox
                id="controlledCheckboxCheck"
                defaultChecked={value}
                onClick={() => {
                  console.log(tableMeta.rowData[2]);
                  if (tableMeta.rowData[2] === 1) {
                    let aux = listaPermisos;
                    aux.find(
                      (element) => element.idPermiso === tableMeta.rowData[0]
                    ).Habilitado = 0;
                    setListaPermisos(aux);
                  }
                  if (tableMeta.rowData[2] === 0) {
                    let aux = listaPermisos;
                    aux.find(
                      (element) => element.idPermiso === tableMeta.rowData[0]
                    ).Habilitado = 1;
                    setListaPermisos(aux);
                  }
                }}
              />
            </Row>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Permisos",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
                      if(expandedRows.data.length>1){
                        let original = expandedRows.data;
                        let aux = expandedRows.data.slice(dataIndex,-1);
                        expandedRows.data = aux;
                        setMostradas(aux);
                        return true;
                      }
                      else{
                        
                      }
                      //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
                      return true;
                      */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData) => {
      console.log(rowData);
    },
    /*
                    renderExpandableRow: (rowData, rowMeta) => {
                      if(!isTabletOrMobile){
                        const colSpan = rowData.length + 1;
                        return (
                          <TableRow>
                            <TableCell colSpan={colSpan} >
                              <Card>{rowData}</Card>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    },
                    */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const handleBoton = () => {
    console.log("Boton");
    let data = { idUsuario: id, permisos: listaPermisos };
    PermisosService.updatePermisos(data).then(
      (response) => {
        console.log(response.data);
        toast.success(<div>Permisos Actualizados</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        navigate(-1);
      },
      (error) => {
        console.log(error);
        toast.error(<div>{error}</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
      }
    );
  };

  if (responseCode === 200) {
    return (
      <Container>
        <h3>Editar ID: {id} </h3>
        <MUIDataTable
          ref={containerRef}
          title={"Lista de Permisos"}
          data={listaPermisos}
          columns={columns}
          options={options}
          id="tabla"
        ></MUIDataTable>
        <Col>
          <Row
            className="d-flex justify-content-center"
            style={{ paddingTop: 10 }}
          >
            <Button onClick={() => handleBoton()}>Cambiar Permisos</Button>
          </Row>
        </Col>
      </Container>
    );
  } else {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{content}</h3>
        </header>
      </div>
    );
  }
};

export default TablaPermisos;
