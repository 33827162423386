import React, { useState, useEffect } from "react";

import PermisosService from "../services/permisos.service";
import EventBus from "../common/EventBus";
import { ListGroup } from "react-bootstrap/";

import CustomTabs from "./customCard/customTabs";
import TaskUsuariosPermisos from "./customCard/TaskUsuariosPermisos";

import styles from "./customCard/dashboardStyle.js";

import BadgeIcon from "@mui/icons-material/Badge";

const Permisos = () => {
  const [content, setContent] = useState("");
  const [responseCode, setResponseCode] = useState("");
  const [empleados, setEmpleados] = useState([]);

  useEffect(() => {
    PermisosService.getUsersConPermisos().then(
      (response) => {
        setEmpleados(response.data);
        setResponseCode(response.status);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  if (responseCode === 200) {
    return content !== undefined && content !== "No token provided!" ? (
      <div>
        <h1>Permisos</h1>

        {empleados.length > 0 ? (
          <ListGroup
            horizontal={"md"}
            style={{ backgroundColor: "#F9F2ED", borderStyle: "hidden" }}
          >
            <CustomTabs
              headerColor="primary"
              tabs={[
                {
                  tabName: "Empleados",
                  tabIcon: BadgeIcon,
                  tabContent: (
                    <TaskUsuariosPermisos
                      checkedIndexes={[0, 3]}
                      tasksIndexes={[0, 1, 2, 3]}
                      tasks={empleados}
                    />
                  ),
                },
              ]}
            />
          </ListGroup>
        ) : null}
      </div>
    ) : null;
  } else {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{content}</h3>
        </header>
      </div>
    );
  }
};

export default Permisos;
