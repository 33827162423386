import React from 'react';
import { Page, Document, Image, StyleSheet,Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
import CodigosDeBarrasFila from './CodigosDeBarraFila'

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
    }, 
  
  });
    
  
const CodigosDeBarras = ({items}) => (
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <View >
                        {
                            items?.map((fila, index) => (
                                <View style={{ display: "flex", flexDirection: 'row',}}>
                                    <CodigosDeBarrasFila fila={fila}/>
                                </View>
                                ))
                            
                        }
                    </View>
                </Page>
            </Document>
        );

export default CodigosDeBarras